import React from 'react';
import { connect } from 'react-redux';
import ConvertIntToLetter from '../../Utilities/LetterConversion';

function mapStateToProps(store) {
    return ({
    });
}

class QuestionField extends React.Component {
    render() {
        return (
            <div 
                className={'TransparentButton BlueHaze QuestionField' + (this.props.IsSelected ? ' active' : '')} 
                onClick={this.props.onSelectResponse}
            >
                <div>{(this.props.IsNumbered ? 
                        this.props.FieldOrder + 1 :
                        ConvertIntToLetter(this.props.FieldOrder)) + ')'}
                </div>
                <div>{this.props.Field}</div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(QuestionField);