import React from 'react';

function SettingsMenuPresentation(props) {
	function precomputedOnUpdateSelectGeneric(selectType) {
		return (event) => {props.onUpdateSelectGeneric(selectType, event);};
	}
	return (
		<div className='MediaConfigurationMenuPresentationWrapperOuter NoiseBackground'>
			<div className='MediaConfigurationMenuPresentationWrapperInner'>
				<div className={(props.stage === 'VideoInput' ? 'Active' : '')}>Webcam:</div>
				<select className={(props.stage === 'VideoInput' ? 'Active' : '')} value={(props.currentVideoInput ? props.currentVideoInput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('videoInputList')}>
					<option value={undefined} disabled>- Select Video Input Device -</option>
					{props.videoInputList.map(input => {
						return <option value={input.deviceId} key={input.deviceId}>{input.label}</option>;
					})}
				</select>
				<div className={(props.stage === 'AudioConfiguration' ? 'Active' : '')}>Microphone:</div>
				<select className={(props.stage === 'AudioConfiguration' ? 'Active' : '')} value={(props.currentAudioInput ? props.currentAudioInput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioInputList')}>]
					<option value={undefined} disabled>- Select Audio Input Device -</option>
					{props.audioInputList.map(input => {
						return <option value={input.deviceId} key={input.deviceId}>{input.label}</option>;
					})}
				</select>
				<div className={(props.stage === 'AudioConfiguration' ? 'Active' : '')} >Speaker:</div>
				<select className={(props.stage === 'AudioConfiguration' ? 'Active' : '')} value={(props.currentAudioOutput ? props.currentAudioOutput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioOutputList')}>
					<option value={undefined} disabled>- Select Audio Output Device -</option>
					{props.audioOutputList.map(output => {
						return <option value={output.deviceId} key={output.deviceId}>{output.label}</option>;
					})}
				</select>
			</div>
		</div>
	);
}

export default SettingsMenuPresentation;