import React from 'react';
import { connect } from 'react-redux'

import CustomVideo from '../../CustomVideo';
import RemoteMediaHolder from '../../../Utilities/Gnural/RemoteMediaHolder';
import ShowStateInformationPresentation from './ShowStateInformationPresentation';
import { setShowQueueOverlay } from '../../../Redux/Actions/userinterfaceActions';
import '../../../Utilities/Gnural/WebRTCStats';
import './RemoteVideoPanel.css';

function mapStateToProps(store) {
    return {
        remoteMediaStreamID: store.media.remoteMediaStreamID,
        currentAudioOutput: store.media.currentAudioOutput,
        fullscreenRemote: store.userinterface.fullscreenRemote,
        ShowEmbedUrl: store.callin.ShowEmbedUrl,
        inShow: store.callin.inShow
    };
}

class RemoteVideoPanel extends React.Component {
    constructor(props) {
        super(props);

        this.onSetShowQueueOverlayOn = this.onSetShowQueueOverlayOn.bind(this);
        this.onSetShowQueueOverlayOff = this.onSetShowQueueOverlayOff.bind(this);

        this.state = {
            remoteStream: undefined
        };
    }

    componentDidMount() {
        this.setState({
            remoteStream: RemoteMediaHolder.getRemoteStream()
        });
    }

    componentDidUpdate(nextProps) {
        if (nextProps.remoteMediaStreamID !== this.props.remoteMediaStreamID) {
            this.setState({
                remoteStream: RemoteMediaHolder.getRemoteStream()
            });
        }
    }

    onSetShowQueueOverlayOn() {
        if (this.props.ShowEmbedUrl && this.props.inShow)
        {
            this.props.dispatch(setShowQueueOverlay(true));
        }
    }
    
    onSetShowQueueOverlayOff() {
        if (this.props.ShowEmbedUrl && this.props.inShow)
        {
            this.props.dispatch(setShowQueueOverlay(false));
        }
    }

    render() {
        return (
            <div className='RemoteVideoWrapper NoiseBackground' onMouseOver={this.onSetShowQueueOverlayOff} 
                onMouseOut={this.onSetShowQueueOverlayOn}>
                <ShowStateInformationPresentation/>
                {
                    (this.props.ShowEmbedUrl && !this.props.remoteMediaStreamID && this.props.inShow ?
                        <iframe className='RemoteIFrame' title="RemoteVideo" frameBorder="0" src={this.props.ShowEmbedUrl} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
                        //<iframe className='RemoteVideo' title="RemoteVideo" frameBorder="0" src="https://www.youtube.com/embed/ytFQ3qTjXHk?controls=0&autoplay=1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                        :
                        null
                    )
                }
                <CustomVideo
                    className={(this.props.fullscreenRemote ? 'RemoteVideoFullscreen' : 'RemoteVideo')}
                    srcObject={this.state.remoteStream}
                    muted={false}
                    currentAudioOutput={this.props.currentAudioOutput}
                    shouldContainFraming={false}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps)(RemoteVideoPanel);