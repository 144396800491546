import GSocket from '../../Utilities/Gnural/websockets';

export function setSelectedUserName(UserName) {
    return {
        type: 'ON_SET_SELECTED_USERNAME_FULFILLED',
        payload: {UserName}
    };
}

export function setSelectedDispName(DispName) {
    return {
        type: 'ON_SET_SELECTED_DISPNAME_FULFILLED',
        payload: {DispName}
    };
}

export function setSelectedFirstName(FirstName) {
    return {
        type: 'ON_SET_SELECTED_FIRSTNAME_FULFILLED',
        payload: {FirstName}
    };
}

export function setSelectedLastName(LastName) {
    return {
        type: 'ON_SET_SELECTED_LASTNAME_FULFILLED',
        payload: {LastName}
    };
}

export function setSelectedEmail(Email) {
    return {
        type: 'ON_SET_SELECTED_EMAIL_FULFILLED',
        payload: {Email}
    };
}

export function setSelectedCompanyName(CompanyName) {
    return {
        type: 'ON_SET_SELECTED_COMPANYNAME_FULFILLED',
        payload: {CompanyName}
    };
}

export function setSelectedLocation(Location) { // Not implemented on Backend....
    return {
        type: 'ON_SET_SELECTED_LOCATION_FULFILLED',
        payload: {Location}
    };
}

export function setSelectedPassword(Password) {
    return {
        type: 'ON_SET_SELECTED_PASSWORD_FULFILLED',
        payload: {Password}
    };
}

export function setSelectedMatchPassword(MatchPassword) {
    return {
        type: 'ON_SET_SELECTED_MATCHPASSWORD_FULFILLED',
        payload: {MatchPassword}
    };
}

export function setChangedAvatar(imageData) {
    return {
        type: 'ON_SET_TEMPORARY_AVATAR_FULFILLED',
        payload: imageData
    };
}

export function setUserShortDesc(UserId, DispName, CompanyName, Email, Location) {
	return (dispatch) => {
		if (!UserId) {
			dispatch({type: 'ON_SET_USER_SHORT_DESCR_REJECTED', error: 'Invalid UserId'});
			return;
        }
        if (!DispName) {
            dispatch({type: 'ON_SET_USER_SHORT_DESCR_REJECTED', error: 'Invalid Display Name'});
            return;
        }
        if (!Email) {
            dispatch({type: 'ON_SET_USER_SHORT_DESCR_REJECTED', error: 'Invalid Email'});
            return;
        }
		GSocket.websocket.publish('setUserShortDescr', {UserId, DispName, CompanyName, Email, Location});
		dispatch({type: 'ON_SET_USER_SHORT_DESCR_PENDING', payload: {UserId, DispName, CompanyName, Email}});
	}
}

export function setAvatar(imageData, UserId) {
	return (dispatch) => {
		if (UserId === undefined) {
			dispatch({type: 'ON_SET_AVATAR_REJECTED', error: 'Invalid UserId'});
			return;
		}

		if (!imageData) {
			dispatch({type: 'ON_SET_AVATAR_REJECTED', error: 'No Image Data'});
			return;
		}

		var extension, imageBytes;
		var tempString;
		
		imageBytes = imageData.split(',')[1];
		if (!imageBytes) {
			dispatch({type: 'ON_SET_AVATAR_REJECTED', error: 'setAvatar does not have a valid Byte Information'});
			return;
		}
		tempString = imageData.split(',')[0];
		if (!tempString) {
			dispatch({type: 'ON_SET_AVATAR_REJECTED', error:'setAvatar can not find Image extension, Failed First Stage'});
			return;
		}
		tempString = tempString.split(';')[0];
		if (!tempString) {
			dispatch({type: 'ON_SET_AVATAR_REJECTED', error: 'setAvatar can not find Image extension, Failed Second Stage'});
			return;
		}
		extension = tempString.split('/')[1];
		if (!extension) {
			dispatch({type: 'ON_SET_AVATAR_REJECTED', error: 'setAvatar can not find Image extension, Failed Third Stage'});
			return;
		}
		if (!(extension === "jpeg")) {
			dispatch({type: 'ON_SET_AVATAR_REJECTED', error: 'setAvatar extension is not of Type jpeg'});
			return;
		}
		extension = ".jpg";
		
		GSocket.websocket.publish("setAvatar", {UserId: UserId, Extention: extension, ImageBytes: imageBytes});
		dispatch({type: 'ON_SET_AVATAR_PENDING'});
	}
}

export function setPassword(UserName, UserId, password, matchPassword) {
	return (dispatch) => {
		if (!UserName) {
			dispatch({type: 'ON_SET_PASSWORD_REJECTED', error: 'Invalid User Name to Set Password!'});
			return;
        }
        if (!UserId) {
            dispatch({type: 'ON_SET_PASSWORD_REJECTED', error: 'Invalid UserId to Set Password'});
            return;
        }
		if (!password) {
			dispatch({type: 'ON_SET_PASSWORD_REJECTED', error: 'Invalid Password to Set Password!'});
			return;
		}
		if (password !== matchPassword) {
			dispatch({type: 'ON_SET_PASSWORD_REJECTED', error: 'Passwords Do Not Match'});
			return;
		}
		GSocket.websocket.publish('setGnuralPassword', {User: UserName, UserId, Pass: password});
		dispatch({type: 'ON_SET_PASSWORD_PENDING'});
	}
}

export function signupUser(UserName, Password, MatchPassword, FirstName, LastName, Email, CompanyName, Hostname) {
    return (dispatch) => {
        if (!UserName) {
            dispatch({type: 'ON_SIGNUP_REJECTED', error: 'Invalid Username!'});
            return;
        }
        if (!Password) {
            dispatch({type: 'ON_SIGNUP_REJECTED', error: 'Please Enter a Password!'});
            return;
        }
        if (!MatchPassword) {
            dispatch({type: 'ON_SIGNUP_REJECTED', error: 'Please Enter your Password Again!'});
            return;
        }
        if (Password !== MatchPassword) {
            dispatch({type: 'ON_SIGNUP_REJECTED', error: 'Passwords do not Match!'});
            return;
        }
        if (!FirstName) {
            dispatch({type: 'ON_SIGNUP_REJECTED', error: 'Please Enter your Name!'});
            return;
        }
        if (!LastName) {
            dispatch({type: 'ON_SIGNUP_REJECTED', error: 'Please Enter your Last Name!'});
            return;
        }
        if (!Email) {
            dispatch({type: 'ON_SIGNUP_REJECTED', error: 'Please Enter your Email!'});
            return;
        }
        /*if (!CompanyName) {
            dispatch({type: 'ON_SIGNUP_REJECTED', error: 'Please Enter your Company Name!'});
            return;
        }*/
        if (!Hostname) {
            dispatch({type: 'ON_SIGNUP_REJECTED', error: 'No Hostname found, Unable to Process Signup'});
            return;
        }
        GSocket.websocket.publish('Signup', {User: UserName, Pass: Password, First: FirstName, Last: LastName, Email, CompanyName, ReqId: Hostname});
        dispatch({type: 'ON_SIGNUP_PENDING', payload: {
            User: UserName,
            Pass: Password,
            First: FirstName,
            Last: LastName,
            Email,
            CompanyName,
            ReqId: Hostname
        }});
    }
}

export function finishSignup(ConfId) {
    return (dispatch) => {
        if (!ConfId) {
            dispatch({type: 'ON_FINISH_SIGNUP_REJECTED', error: 'No Confirmation ID Found'});
            return;
        }
        GSocket.websocket.publish('FinishSignUp', {ReqId: ConfId});
        dispatch({type: 'ON_FINISH_SIGNUP_PENDING', payload: ConfId});
    }
}
