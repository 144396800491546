import GSocket from '../../Utilities/Gnural/websockets';

export function setContactsSearchTerm(searchTerm) {
	return ({
		type: 'SET_CONTACTS_SEARCH_TERM',
		payload: searchTerm
	});
}

export function setContactsActiveTab(activeTab) {
	return ({
		type: 'SET_CONTACTS_ACTIVE_TAB',
		payload: activeTab
	});
}

export function setContactsSelectedUser(selectedUser) {
	return ({
		type: 'SET_CONTACTS_SELECTED_USER',
		payload: selectedUser
	});
}

export function addContact(UserId, ContactId, AddressBookGroupId) {
	return (dispatch) => {
		if (!UserId) {
			dispatch({type: 'ON_ADD_CONTACT_REJECTED', error: 'Invalid UserId'});
			return;
		}
		if (!ContactId) {
			dispatch({type: 'ON_ADD_CONTACT_REJECTED', error: 'Invalid ContactId'});
			return;
		}
		if (!AddressBookGroupId) {
			dispatch({type: 'ON_ADD_CONTACT_REJECTED', error: 'Invalid Address Book Group Id'});
			return;
		}
		GSocket.websocket.publish('AddContact', {UserId, ContactId, GroupId: AddressBookGroupId});
		dispatch({type: 'ON_ADD_CONTACT_PENDING', payload: {UserId, ContactId, GroupId: AddressBookGroupId }});
	}
}

export function removeContact(UserId, ContactId, AddressBookGroupId) {
	return (dispatch) => {
		if (!UserId) {
			dispatch({type: 'ON_REMOVE_CONTACT_REJECTED', error: 'Invalid UserId'});
			return;
		}
		if (!ContactId) {
			dispatch({type: 'ON_REMOVE_CONTACT_REJECTED', error: 'Invalid ContactId'});
			return;
		}
		if (!AddressBookGroupId) {
			dispatch({type: 'ON_REMOVE_CONTACT_REJECTED', error: 'Invalid Address Book Group Id'});
			return;
		}
		GSocket.websocket.publish('RemoveContact', {UserId, ContactId, GroupId: AddressBookGroupId});
		dispatch({type: 'ON_REMOVE_CONTACT_PENDING', payload: {UserId, ContactId, GroupId: AddressBookGroupId }});
	}
}
