import React from 'react';
import { connect } from 'react-redux';
import { setJoinShowField, enterShow } from '../../../Redux/Actions/showActions';
import './JoinShowPanel.css';

function mapStateToProps(store) {
    return {
        CookieGuid: store.callin.CookieGuid,
        ShowName: store.callin.ShowName,
        Name: store.callin.Name,
        Email: store.callin.Email,
        Phone: store.callin.Phone,
        Twitter: store.callin.Twitter,
        Facebook: store.callin.Facebook,
        GeoLocation: store.callin.GeoLocation,
        Notes: store.callin.Notes,
        ShowError: store.callin.ShowError,
        RestrictedFields: store.callin.RestrictedFields
    };
}

class JoinShowPanel extends React.Component {
    constructor(props) {
        super(props);

        this.onSetJoinShowField = this.onSetJoinShowField.bind(this);
        this.onEnterShow = this.onEnterShow.bind(this);
    }

    onSetJoinShowField(event) {
        if (!event || !event.target) {
            return;
        }
        this.props.dispatch(setJoinShowField(event.target.name, event.target.value));
    }

    onEnterShow() {
        this.props.dispatch(enterShow({...this.props}));
    }

    render() {
        return (
            <div className={'FlexColumnWrapper'}>
                <div className={'IndentHeaderWrapper'}>
                    <div>Join a Show</div>
                    {this.props.ShowError ? <div className={'Error'}>{this.props.ShowError}</div> : null}
                </div>
                <div className={'IsolatedInputWrapper'}>
                    <input type='text' onChange={this.onSetJoinShowField} name={'ShowName'} value={this.props.ShowName} placeholder='Enter the Show Name (Required)'/>
                    <input type='text' onChange={this.onSetJoinShowField} name={'Name'} value={this.props.Name} placeholder='Enter Your Name/Alias (Required)'/>
                    {this.props.RestrictedFields ?
                        null
                        :
                        <React.Fragment>
                            <input type='text' onChange={this.onSetJoinShowField} name={'Email'} value={this.props.Email} placeholder='Enter Your Email'/>
                            <input type='text' onChange={this.onSetJoinShowField} name={'Phone'} value={this.props.Phone} placeholder='Enter Your Phone Number'/>
                            <input type='text' onChange={this.onSetJoinShowField} name={'Twitter'} value={this.props.Twitter} placeholder='Enter Your Twitter Handle'/>
                            <input type='text' onChange={this.onSetJoinShowField} name={'Facebook'} value={this.props.Facebook} placeholder='Enter Your Facebook Handle'/>
                            <input type='text' onChange={this.onSetJoinShowField} name={'GeoLocation'} value={this.props.GeoLocation} placeholder='Enter Your City/Location'/>
                        </React.Fragment>
                    }
                    <input type='text' onChange={this.onSetJoinShowField} name={'Notes'} value={this.props.Notes} placeholder='Enter Any Additional Questions/Comments'/>
                </div>
                <div className={'HorizontalRule'}></div>
                <div className={'BodyFiller JoinShowBodyFiller NoiseBackground'}>
                    <div className={'JoinShowLegal'}>By clicking "JOIN NOW" you Agree to have your Audio, Video, and any Submitted Information used in this Production</div>
                </div>
                <div className={'ShowButtonWrapper'} onClick={this.onEnterShow}>
                    <div className={'TransparentButton GreenHaze Static'}>Join Now</div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(JoinShowPanel);