import React from 'react';
import Main from './Main';
import SignupConfirmation from './SignupConfirmation';
import MediaConfigurationWizard from './MediaConfigurationWizard';
import PermissionScreen from './PermissionScreen';
import Connecting from './Connecting';
import { connect } from 'react-redux';
import { AcquireBrowserInfo, getConfIdFromURL, getParamFromURL } from '../Utilities/Gnural/SessionInformation';
import { setBrowserInformation, setConfId } from '../Redux/Actions/sessionActions';
import { setShouldConfigureMedia } from '../Redux/Actions/mediaActions';
import { setShowEmbedUrl } from '../Redux/Actions/showActions';
import '../Utilities/Gnural/GnuralRTC';
import './App.css';

import LocalMediaHandler from '../Utilities/Gnural/LocalMediaHandler';



function mapStateToProps(store) {
	return {
		websocket: {
			ConnectionStatus: store.websocket.ConnectionStatus
		},
		user: {
			loginStatus: store.user.loginStatus
		},
		ConfId: store.sessionInformation.ConfId,
		VideoPermission: store.sessionInformation.VideoPermission,
		AudioPermission: store.sessionInformation.AudioPermission,
		ShouldConfigureMedia: store.sessionInformation.ShouldConfigureMedia,
		isMobileDevice: store.sessionInformation.isMobileDevice
	};
}

class App extends React.Component {
	constructor(props) {
		super(props);

		this.displayProperScreen = this.displayProperScreen.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(setBrowserInformation(AcquireBrowserInfo()));
		this.props.dispatch(setShowEmbedUrl(decodeURIComponent(getParamFromURL('embedurl'))));
		LocalMediaHandler.init();
		let confId = getConfIdFromURL();
		
		if (confId) {
			this.props.dispatch(setConfId(confId));
		}
		if (!localStorage.getItem('HasConfiguredMedia')) {
			this.props.dispatch(setShouldConfigureMedia(true));
		}
	}

	componentDidUpdate(nextProps) {
		if (nextProps.isMobileDevice) {
			let pathname = window.location.pathname;
			if (pathname.charAt(pathname.length-1) === '/') {
				pathname = pathname.slice(0, pathname.length-1);
			}
			window.location.replace("https://" + window.location.hostname + "/indexMobile.html" + (
				window.location.search ? 
					window.location.search + "&show=" + encodeURIComponent(pathname) :
					'?show=' + encodeURIComponent(pathname)
				)
			);
			//window.location.replace("https://" + window.location.hostname + "/indexMobile.html" + pathname + window.location.search); 
			//window.location.replace("https://" + window.location.hostname + pathname + "/indexMobile.html" + window.location.search); 
		}
	}

	displayProperScreen() {
		if (this.props.websocket.ConnectionStatus !== 'CONNECTED') {
			return <Connecting/>;
		}
		if (this.props.ConfId) {
			return <SignupConfirmation/>;
		}
		if (!this.props.VideoPermission || !this.props.AudioPermission) {
			return <PermissionScreen/>;
		}
		if (this.props.ShouldConfigureMedia) {
			return <MediaConfigurationWizard/>
		}
		return <Main/>;
	}

	render() {
		return this.displayProperScreen();
	}
}

export default connect(mapStateToProps)(App);
