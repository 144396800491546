import { createStore, applyMiddleware } from 'redux';
import Reducers from './Reducers';
import Logger from '../Utilities/Logging';
import thunk from 'redux-thunk';
import webRTCMiddleware from './Middleware/webRTCMiddleware';
import loginMiddleware from './Middleware/loginMiddleware';
import userSearchMiddleware from './Middleware/userSearchMiddleware';
import localStorageMiddleware from './Middleware/localStorageMiddleware';


const reduxLogger = store => next => action => {
	if (action.type !== 'UPDATE_CURRENT_TIME' && action.type !== 'ON_WEBRTC_STATS') {
		Logger.debug('reduxLogger', 'Action Dispatched: ', action);
		Logger.debug('reduxLogger', 'Pre Store State: ', store.getState());
		const state = next(action);
		Logger.debug('reduxLogger', 'Post Store State: ', store.getState());
		return state;
	} else {
		next(action);
	}
};

const reduxCrashReporter = () => next => action => {
	try {
		return next(action);
	} catch (e) {
		Logger.error('reduxCrashReporter', 'Action: ', action, e);
	}
};

/*const MediaHandlerMiddleware = () => next => action => {
	if (action.type === 'ON_REMOTE_STREAM' && action.payload && action.payload.stream && action.payload.stream.id) {
		RemoteMediaHolder(action.payload);
		next({type: 'ON_REMOTE_STREAM', payload: action.payload.stream.id});
		return;
	}
	next(action);
};*/

const reduxStore = createStore(Reducers, applyMiddleware(reduxLogger, reduxCrashReporter, thunk, userSearchMiddleware, localStorageMiddleware, webRTCMiddleware, loginMiddleware));

export default reduxStore;