import React from 'react';
import { connect } from 'react-redux';
import './Polling.css';
import QuestionField from './QuestionField';
import { SetNewPollingQuestionResponse, SetPollingQuestionResponse } from '../../Redux/Actions/pollingActions';

function mapStateToProps(store) {
    return ({
        ...store.polling,
    });
}

class Polling extends React.Component {
    constructor(props) {
        super(props);

        this.onSelectResponse = this.onSelectResponse.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.onSubmitResponses = this.onSubmitResponses.bind(this);
    }

    onSelectResponse(FieldId) {
        return () => {
            if (!FieldId) {
                return;
            }
            if (this.props.NewUserResponses.includes(FieldId)) {
                this.props.dispatch(SetNewPollingQuestionResponse(this.props.NewUserResponses.filter(fieldId => fieldId !== FieldId)));
                return;
            }
            if (!this.props.IsMultiselect) {
                this.props.dispatch(SetNewPollingQuestionResponse([FieldId]));
                return;
            }
            this.props.dispatch(SetNewPollingQuestionResponse([...this.props.NewUserResponses, FieldId]));
        }
    }

    canSubmit() {
        if (this.props.UserResponses.length !== this.props.NewUserResponses.length) {
            return true;
        }
        
        for (let index = 0; index < this.props.UserResponses.length; index ++)
        {
            if (this.props.UserResponses[index] !== this.props.NewUserResponses[index]) {
                return true;
            }
        }
        return false;
    }

    onSubmitResponses() {
        if (!this.canSubmit()) {
            return;
        }
        this.props.dispatch(SetPollingQuestionResponse(this.props.NewUserResponses));
    }

    render() {
        return (
            <div className={'PollingWrapper'}>
                <div className={'PollingQuestionTextWrapper NoiseBackground'}>
                    <div>{this.props.QuestionText}</div>
                </div>
                <div className={'PollingQuestionFieldOuterWrapper'}>
                    <div className={'PollingQuestionFieldInnerWrapper NoiseBackground'}>
                        {this.props.Fields.map(field => 
                            <QuestionField
                                {...field}
                                onSelectResponse={this.onSelectResponse(field.QuestionFieldId)}
                                IsNumbered={this.props.IsNumbered}
                                IsSelected={this.props.NewUserResponses.includes(field.QuestionFieldId)}
                                key={'Question-Field-' + field.QuestionFieldId}
                            />)}
                    </div>
                    <div
                        onClick={this.onSubmitResponses}
                        className={'TransparentButton GreenHaze PollingSubmitButton Static' + (this.canSubmit() ? '' : ' inactive')}
                        >
                            <div>Submit</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Polling);