import GSocket from '../../Utilities/Gnural/websockets';

export function setShowEmbedUrl(url)
{
	return (dispatch) =>
	{
		if (!url)
		{
			return;
		}
		dispatch({
			type: 'SET_SHOW_EMBED_URL',
			payload: url
		});

	}
}

export function setJoinShowField(fieldName, fieldValue) {
	return ({
		type: 'SET_JOIN_SHOW_FIELD',
		payload: {
			fieldName,
			fieldValue
		}
	});
}

export function enterShow(ShowParameters) {
	return (dispatch) => {
		if (!ShowParameters) {
			dispatch({
				type: 'ON_ENTER_SHOW_REJECTED',
				error: 'No Show Parameters Given'
			});
			return;
		}
		if (!ShowParameters.ShowName) {
			dispatch({
				type: 'ON_ENTER_SHOW_REJECTED',
				error: 'Show Name is Required'
			});
			return;
		}
		if (!ShowParameters.Name) {
			dispatch({
				type: 'ON_ENTER_SHOW_REJECTED',
				error: 'Your Name is Required'
			});
			return;
		}
		/*if (!ShowParameters.Email) {
			dispatch({
				type: 'ON_ENTER_SHOW_REJECTED',
				error: 'Your Email is Required'
			});
			return;
		}*/

		GSocket.websocket.publish('EnterShow', {
			CookieGuid: ShowParameters.CookieGuid || '',
			ShowName: ShowParameters.ShowName,
			Name: ShowParameters.Name,
			Email: ShowParameters.Email || '',
			Phone: ShowParameters.Phone || '',
			Twitter: ShowParameters.Twitter || '',
			Facebook: ShowParameters.Facebook || '',
			GeoLocation: ShowParameters.GeoLocation || '',
			Notes: ShowParameters.Notes || ''
		});
		dispatch({
			type: 'ON_ENTER_SHOW_PENDING',
			payload: {...ShowParameters}
		});
	}
}

export function leaveShow(ShowParameters) {
	return (dispatch) => {
		if (!ShowParameters) {
			dispatch({
				type: 'ON_LEAVE_SHOW_REJECTED',
				error: 'No Show Parameters Given'
			});
			return;
		}
		if (!ShowParameters.ShowName) {
			dispatch({
				type: 'ON_LEAVE_SHOW_REJECTED',
				error: 'No ShowName to Leave'
			});
			return;
		}
		if (!ShowParameters.CookieGuid) {	
			dispatch({
				type: 'ON_LEAVE_SHOW_REJECTED',
				error: 'No CookieGuid to Leave Show'
			});
			return;
		}
		GSocket.websocket.publish('leaveshow', {
			CookieGuid: ShowParameters.CookieGuid,
			ShowName: ShowParameters.ShowName,
			Name: ShowParameters.Name || '',
			Email: ShowParameters.Email || '',
			Phone: ShowParameters.Phone || '',
			Twitter: ShowParameters.Twitter || '',
			Facebook: ShowParameters.Facebook || '',
			GeoLocation: ShowParameters.GeoLocation || '',
			Notes: ShowParameters.Notes || ''
		});
		dispatch({
			type: 'ON_LEAVE_SHOW_PENDING',
			payload: {...ShowParameters}
		});
	}
}