import React from 'react';
import { connect } from 'react-redux';
import UserListPresentation from './UserListPresentation';
import {
    setContactsSearchTerm,
    setContactsActiveTab,
    setContactsSelectedUser,
    addContact,
    removeContact
} from '../../../Redux/Actions/contactsActions';
import {
    callUserId
} from '../../../Redux/Actions/callActions';
import {
    openChat
} from '../../../Redux/Actions/chatActions';
import './ContactsDirectory.css';


function sortUsers(user1, user2) {
    if (!user1) {
        return 1;
    }
    if (!user2) {
        return -1;
    }
    if (user1.UStatus === user2.UStatus) {
        if (user1.DispName < user2.DispName) {
            return -1;
        }
        return 1;
    }
    if (user1.UStatus === 'OffLine') {
        return 1;
    }
    return -1;
}

function mapStateToProps(store) {
    return ({
        ContactsDirectory: Object.values(store.usermanagement.ContactsDirectory || {}).sort(sortUsers),
        MainSearch: Object.values(store.usermanagement.MainSearch || {}).sort(sortUsers),
        currentContactsTab: store.userinterface.currentContactsTab,
        currentContactsSearchTerm: store.userinterface.currentContactsSearchTerm,
        currentContactsSelectedUser: store.userinterface.currentContactsSelectedUser,
        UserId: store.user.UserId,
        ContactsGroupId: store.usermanagement.ContactsGroupId,
        DefaultAvatar: store.usermanagement.DefaultAvatar,
        RTCCallState: store.call.CallState
    });
}

class ContactsDirectory extends React.Component {
    constructor(props) {
        super(props);

        this.onSetActiveTab = this.onSetActiveTab.bind(this);
        this.onUpdateSearchTerm = this.onUpdateSearchTerm.bind(this);
        this.onSetSelectedUser = this.onSetSelectedUser.bind(this);
        this.displayProperButton = this.displayProperButton.bind(this);
        this.onCallUser = this.onCallUser.bind(this);
        this.onMessageUser = this.onMessageUser.bind(this);
        this.onAddUserToContacts = this.onAddUserToContacts.bind(this);
        this.onRemoveUserFromContacts = this.onRemoveUserFromContacts.bind(this);
    }

    onSetActiveTab(activeTab) {
        return () => {
            this.props.dispatch(setContactsActiveTab(activeTab));
            this.props.dispatch(setContactsSelectedUser({}));
        };
    }

    onUpdateSearchTerm(event) {
        if (!event || !event.target) {
            return;
        }
        this.props.dispatch(setContactsActiveTab('Search'));
        this.props.dispatch(setContactsSelectedUser({}));
        this.props.dispatch(setContactsSearchTerm(event.target.value));
    }

    onSetSelectedUser(user) {
        return () => {
            this.props.dispatch(setContactsSelectedUser(user));
        }
    }

    displayProperButton() {
        const isInContacts = this.props.ContactsDirectory.filter(contact => {
            return contact.UserId === this.props.currentContactsSelectedUser.UserId
        }).length;

        if (isInContacts) {
            return <div className={'remove TransparentButton RedHaze Static'} onClick={this.onRemoveUserFromContacts}>Remove Contact</div>
        }
        return <div className={'add TransparentButton GreenHaze Static'} onClick={this.onAddUserToContacts}>Add Contact</div>
    }

    onCallUser() {
        if (!this.props.currentContactsSelectedUser || this.props.currentContactsSelectedUser.UStatus !== 'OnLine' || this.props.currentContactsSelectedUser.UserId === this.props.UserId || this.props.RTCCallState === 'ACTIVE') {
            return;
        }
        this.props.dispatch(callUserId(this.props.currentContactsSelectedUser.UserId, this.props.currentContactsSelectedUser.DispName));
    }

    onMessageUser() {
        if (!this.props.currentContactsSelectedUser || this.props.currentContactsSelectedUser.UStatus !== 'OnLine' || this.props.currentContactsSelectedUser.UserId === this.props.UserId) {
            return;
        }
        this.props.dispatch(openChat(this.props.currentContactsSelectedUser.UserId, this.props.currentContactsSelectedUser.DispName));
    }

    onAddUserToContacts() {
        this.props.dispatch(addContact(this.props.UserId, this.props.currentContactsSelectedUser.UserId, this.props.ContactsGroupId));
    }

    onRemoveUserFromContacts() {
        this.props.dispatch(removeContact(this.props.UserId, this.props.currentContactsSelectedUser.UserId, this.props.ContactsGroupId));
    }

    render() {
        return (
            <div className='ContactsDirectoryWrapper'>
                <div className='UserListWrapper'>
                    <div className={'ContactsTabWrapper'}>
                        <div className={'ContactsTab TransparentButton BlueHaze ' + (this.props.currentContactsTab === 'Contacts' ? 'active' : '')} onClick={this.onSetActiveTab('Contacts')}><div>Contacts</div></div>
                        <div className={'ContactsTab TransparentButton BlueHaze ' + (this.props.currentContactsTab === 'Search' ? 'active' : '')} onClick={this.onSetActiveTab('Search')}><div>Search</div></div>
                    </div>
                    <input onChange={this.onUpdateSearchTerm} value={this.props.currentContactsSearchTerm} placeholder={'Search for Users...'}/>
                    {this.props.currentContactsTab === 'Contacts' ? 
                        <UserListPresentation
                            UserList={this.props.ContactsDirectory}
                            onSetSelectedUser={this.onSetSelectedUser}
                            selectedUser={this.props.currentContactsSelectedUser}
                            DefaultAvatar={this.props.DefaultAvatar}
                            onCallUser={this.onCallUser}
                            RTCCallState={this.props.RTCCallState}
                            onMessageUser={this.onMessageUser}
                            displayProperButton={this.displayProperButton}
                            LocalUserId={this.props.UserId}
                        /> :
                        <UserListPresentation
                            UserList={this.props.MainSearch}
                            onSetSelectedUser={this.onSetSelectedUser}
                            selectedUser={this.props.currentContactsSelectedUser}
                            DefaultAvatar={this.props.DefaultAvatar}
                            onCallUser={this.onCallUser}
                            RTCCallState={this.props.RTCCallState}
                            onMessageUser={this.onMessageUser}
                            displayProperButton={this.displayProperButton}
                            LocalUserId={this.props.UserId}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ContactsDirectory);