import React from 'react';
import { connect } from 'react-redux';

import './SignupConfirmation.css';


function mapStateToProps(store) {
    return ({
        ConfId: store.sessionInformation.ConfId,
        ConfirmationState: store.sessionInformation.ConfirmationState,
        ConfirmationError: store.sessionInformation.ConfirmationError,
    });
}

class SignupConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.getProperDisplay = this.getProperDisplay.bind(this);
    }

    getProperDisplay() {
        if (this.props.ConfirmationState === 'PENDING') {
            return (
                <div className={'BrushedMetalGradient SignupConfirmationWrapperOuter'}>
                    <div className={'SignupConfirmationWrapperInner NoiseBackground'}>
                    </div>
                </div>
            );
        }
        if (this.props.ConfirmationState === 'FULFILLED') {
            return (
                <div className={'BrushedMetalGradient SignupConfirmationWrapperOuter'}>
                    <div className={'SignupConfirmationWrapperInner NoiseBackground'}>
                        <div className={'Primary Success'}>Account Created</div>
                        <div className={'Secondary'}>Please close this Tab</div>
                    </div>
                </div>
            )
        }
        return (
            <div className={'BrushedMetalGradient SignupConfirmationWrapperOuter'}>
                <div className={'SignupConfirmationWrapperInner NoiseBackground'}>
                    <div className={'Primary Error'}>Account Creation Failed</div>
                    <div className={'Secondary'}>Please Try to Signup Again</div>
                    <div className={'Tertiary'}>{this.props.ConfirmationError}</div>
                </div>
            </div>
        );
    }

    render() {
        return this.getProperDisplay();
    }
}

export default connect(mapStateToProps)(SignupConfirmation);