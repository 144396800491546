
const defaultState = {
    Context: '',
    DispName: '',
    PeerId: '',
    UserId: 0,
    UserType: '',
    CallState: undefined,
    AutoAnswer: false
}

function call(state={...defaultState}, action) {
    switch(action.type) {
    case 'ON_INCOMING_CALL_PENDING': {
        if (!action.payload || !action.payload.Context) {
            return state;
        }
        return {
            ...state,
            Context: action.payload.Context,
            DispName: action.payload.DispName,
            PeerId: action.payload.PeerId,
            UserId: action.payload.UserId,
            UserType: action.payload.UserType,
            CallState: 'PENDING_INCOMING'
        };
    }
    case 'ON_INCOMING_CALL_FULFILLED': {
        return {...state, CallState: 'ACTIVE'}
    }
    case 'ON_INCOMING_CALL_REJECTED': {
        if (!action.payload || action.payload.UserId !== state.UserId) {
            return state;
        }
        return {...defaultState, AutoAnswer: state.AutoAnswer};
    }
    case 'ON_DROP_USER_ID_FULFILLED': {
        if (!action.payload || action.payload.UserId !== state.UserId) {
            return state;
        }
        return {...defaultState, AutoAnswer: state.AutoAnswer};
    }
    case 'ON_CALL_USER_ID_PENDING': {
        if (!action.payload) {
            return state;
        }
        return {
            ...state,
            DispName: action.payload.DispName,
            UserId: action.payload.UserId,
            CallState: 'PENDING_OUTGOING'
        };
    }
    case 'ON_REMOTE_STREAM': {
        if (!action.payload) {
            return state;
        }
        return {...state, PeerId: action.payload.PeerId, UserId: action.payload.UserId, DispName: action.payload.DispName, CallState: 'ACTIVE'};
    }
    case 'ON_CONTEXT_CHANGED': {
        if (!action.payload) {
            return state;
        }
        let remoteUser = (action.payload.filter(user => {
            return user.UserId === state.UserId
        }) || [])[0];

        if (!remoteUser) {
            return state;
        }
        return {
            ...state,
            Context: remoteUser.Context,
            PeerId: remoteUser.PeerId,
            UserType: remoteUser.UserType,
            DispName: remoteUser.DispName,
            CallState: 'ACTIVE'
        }
    }
    case 'ON_CONTEXT_DENY': {
        if (!action.payload || action.payload.UserId !== state.UserId) {
            return state;
        }
        return {...defaultState, AutoAnswer: state.AutoAnswer};
    }
    case 'ON_SET_CONTEXT_PENDING': {
        if (!action.payload) {
            return state;
        }
        return {...state, Context: action.payload};
    }
    case 'ON_DISCONNECT_SELF_PENDING': {
        return {...defaultState, AutoAnswer: state.AutoAnswer};
    }
    case 'DISCONNECT_PEER_REMOTE': {
        if (!action.payload || action.payload.PeerId !== state.PeerId) {
            return state;
        }
        return {...defaultState, AutoAnswer: state.AutoAnswer};
    }
    case 'ON_DROP_USER_ID_PENDING': {
        if (!action.payload || !action.payload.UserId === state.UserId) {
            return state;
        }
        return ({
            ...state,
            CallState: undefined
        });
    }
    case 'LOGIN_FULFILLED': {
        if (!action.payload) {
            return state;
        }
        return {
            ...state,
            AutoAnswer: action.payload.AutoAnswer
        };
    }
    case 'ON_PEER_CONNECTION_DISCONNECT': {
        if (!action.payload || action.payload.Sender !== state.PeerId) {
            return state;
        }
        return {
            ...defaultState, 
            AutoAnswer: state.AutoAnswer
        };

    }
    case 'SET_AUTO_ANSWER': {
        return {
            ...state,
            AutoAnswer: action.payload || false
        };
    }
    default:
        return state;
    }
}

export default call;