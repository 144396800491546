import React from 'react';
import { connect } from 'react-redux';

import './CenterDivide.css';

function mapStateToProps(store) {
    return ({
        GeneralCallState: store.call.CallState,
        CallInState: store.callin.CallState,
        inShow: store.callin.inShow
    });
}

class CenterDivide extends React.Component {
    constructor(props) {
        super(props);
        
        this.getProperDivideClass = this.getProperDivideClass.bind(this);
    }

    getProperDivideClass() {
        if (this.props.CallInState === 'OnAir') {
            return 'OnAir';
        }
        if (this.props.CallInState === 'OffAir') {
            return 'OffAir';
        }
        if (this.props.GeneralCallState === 'ACTIVE') {
            return 'InCall';
        }
        if (this.props.inShow) {
            return 'InScreening';
        }
        return '';
    }

    render() {
        return (
            <div className='CenterDivide'>
                <div className={'CenterDivideHeader'}/>
                <div className={'CenterDivideBody ' + this.getProperDivideClass()}/>
                <div className={'CenterDivideFooter'}/>
            </div>
        );
    }
}

export default connect(mapStateToProps)(CenterDivide);