import React from 'react';
import { connect } from 'react-redux';

function mapStateToProps(store) {
    return {
        inShow: store.callin.inShow,
        CallState: store.callin.CallState,
        Message: store.callin.Message,
        remotePeerID: store.media.remotePeerID,
        wasInStudioQueue: store.callin.wasInStudioQueue,
        wasInShow: store.callin.wasInShow,
        ShowEmbedUrl: store.callin.ShowEmbedUrl,
        ShowQueueOverlay: store.userinterface.ShowQueueOverlay
    };
}


class ShowStateInformationPresentation extends React.Component {
    constructor(props) {
        super(props);

        this.getProperDisplay = this.getProperDisplay.bind(this);
        this.formatShowColumns = this.formatShowColumns.bind(this);
    }

    formatShowColumns() {
        if (this.props.remotePeerID) {
            return null;
        }
        return (
            <div key={this.props.CallState + "-ShowStateWrapper"} className={'ShowStateWrapperDynamic' + (this.props.ShowEmbedUrl ? ' Embed' : '') }>
                <div className={'ShowStateWrapperColumnOuter BrushedMetalGradient ' + (this.props.CallState === 'Screening' ? 'active' : '')}>
                    <div className={'ShowStateWrapperColumnInner ' + (this.props.CallState === 'Screening' ? 'active' : 'dim')}>
                        <div className={'Header'}>
                            <div>
                                Screening
                            </div>
                            <div>
                                Phase
                            </div>
                        </div>
                        <div className={'Body'}>
                            {this.props.CallState === 'Screening' ? 
                                    <React.Fragment>
                                        <div>
                                            Wait for a Call Screener
                                        </div>
                                        <div>
                                            Queue Position:
                                        </div>
                                        <div className={'PositionIndicator'}>
                                            {this.props.Message}
                                        </div>
                                    </React.Fragment>
                                :
                                <div className={'Completed'}>Completed</div>
                            }
                        </div>
                    </div>
                </div>
                <div className={'ColumnDivide BrushedMetalGradient ' + (this.props.CallState === 'Screening' || !this.props.wasInStudioQueue ? '' : 'active')}>
                    <div></div>
                </div>
                <div className={'ShowStateWrapperColumnOuter BrushedMetalGradient ' + (this.props.CallState === 'Studio' ? 'active' : '')}>
                    <div className={'ShowStateWrapperColumnInner ' + (this.props.CallState === 'Studio' ? 'active' : (this.props.wasInStudioQueue ? 'dim' : ''))}>
                        <div className={'Header'}>
                            <div>Studio</div>
                            <div>Phase</div>
                        </div>
                        <div className={'Body'}>
                            {this.props.CallState !== 'Studio' && this.props.wasInStudioQueue ? 
                                <div className={'Completed'}>Completed</div> 
                            :
                                <React.Fragment>
                                    <div>
                                        Wait for the Studio Operator to
                                    </div>
                                    <div>
                                        Bring you into the Show
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
                <div className={'ColumnDivide BrushedMetalGradient ' + (this.props.wasInShow ? 'active' : '')}>
                    <div></div>
                </div>
                <div className={'ShowStateWrapperColumnOuter BrushedMetalGradient ' + (this.props.CallState === 'Show' ? 'active' : '')}>
                    <div className={'ShowStateWrapperColumnInner ' + (this.props.CallState === 'Show' ? 'active' : (this.props.wasInShow ? 'dim' : ''))}>
                        <div className={'Header'}>
                            <div>
                                Show
                            </div>
                            <div>
                                Phase
                            </div>
                        </div>
                        <div className={'Body'}>
                            {this.props.CallState === 'Show' ? 
                                <div>Please Wait</div>
                            :
                                (this.props.wasInShow ?
                                    <div className={'Completed'}>Completed</div>
                                :
                                    <div>
                                        Participate in the Show
                                    </div>
                                ) 
                            }
                        </div>
                    </div>
                </div>
                <div className={'ColumnSpacer'}>
                </div>
                <div className={'ShowStateWrapperColumnOuter BrushedMetalGradient ' + (this.props.CallState === 'Finished' ? 'active' : '')}>
                    <div className={'ShowStateWrapperColumnInner ' + (this.props.CallState === 'Finished' ? 'active' : '')}>
                        <div className={'Header'}>
                            <div>
                                Finished
                            </div>
                            <div>
                                Phase
                            </div>
                        </div>
                        <div className={'Body'}>
                            <div>
                                You are now Finished, thank you for joining!
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    getProperDisplay() {
        if (!this.props.CallState) {
            return null;
        }
        if (this.props.inShow) {
            switch(this.props.CallState) {
            case 'Screening': {
                return (
                    this.formatShowColumns()
                );
            }
            case 'Studio': {
                return (
                    this.formatShowColumns()
                );
            }
            case 'Finished': {
                return (
                    this.formatShowColumns()
                );
            }
            case 'Show': {
                return (
                    this.formatShowColumns()
                );
            }
            case 'Blocked': {
                return (
                    <div className='ShowStateWrapper Blocked'>
                        <div>BLOCKED</div>
                    </div>
                );
            }
            case 'OnAir': {
                return (
                    <div className='ShowStateWrapper OnAir'>
                        <div>ON-AIR</div>
                    </div>
                );
            }
            case 'OffAir': {
                return (
                    <div className='ShowStateWrapper OffAir'>
                        <div>OFF-AIR</div>
                    </div>
                );
            }
            default:
                return null;
            }
        }
        switch(this.props.CallState) {
        case 'OnAir': {
            return (
                <div className='ShowStateWrapper OnAir'>
                    <div>ON-AIR</div>
                </div>
            );
        }
        case 'OffAir': {
            return (
                <div className='ShowStateWrapper OffAir'>
                    <div>OFF-AIR</div>
                </div>
            );
        }
        default:
            return null;
        }
    }

    render() {
        return (this.props.ShowQueueOverlay ? this.getProperDisplay() : null);
    }
}

export default connect(mapStateToProps)(ShowStateInformationPresentation);

