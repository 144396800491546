import GSocket from '../../Utilities/Gnural/websockets';

export function logInUser(username, password) {
	return (dispatch) => {
		if (!username || typeof(username) !== 'string') {
			dispatch({type: 'LOGIN_REJECTED', payload: {Error: 'Please Enter a Username'}});
			return;
		}
		if (!password || typeof(password) !== 'string') {
			dispatch({type: 'LOGIN_REJECTED', payload: {Error: 'Please Enter a Password'}});
			return;
		}
		GSocket.websocket.publish('login', {
			UserName: username,
			UserPass: password
		});
		dispatch({type: 'LOGIN_PENDING', payload: {Username: username, Password: password}});
	};
}

export function getLTAUsers() {
	return (dispatch) => {
		GSocket.websocket.publish('getltausers', {});
		dispatch({type: 'ON_GET_LTA_USERS_PENDING'});
	};
}

export function logOutUser() {
	return (dispatch) => {
		GSocket.websocket.close();
		dispatch({
			type: 'LOGIN_ENDED'
		});
	}
}
