
export function attemptOpenModal(id) {
	return {
		type: 'ATTEMPT_MODAL_OPEN',
		payload: id
	};
}

export function attemptCloseModal(id) {
	return {
		type: 'ATTEMPT_MODAL_CLOSE',
		payload: id
	};
}

export function updateCurrentTime() {
	return {
		type: 'UPDATE_CURRENT_TIME',
		payload: Date.now()
	};
}

export function setCurrentInformationPanel(panelName) {
	return {
		type: 'UPDATE_CURRENT_INFORMATION_PANEL',
		payload: panelName
	};
}

export function toggleFullscreenRemote() {
	return {
		type: 'TOGGLE_FULLSCREEN_REMOTE'
	};
}

export function setShowQueueOverlay(value) {
	return {
		type: 'SET_SHOW_QUEUE_OVERLAY',
		payload: value
	};
}