const defaultState = {
    QuestionId: '',
    QuestionText: '',
    IsMultiselect: false,
    IsNumbered: false,
    Fields: [],
    UserResponses: [],
    NewUserResponses: [],
    InteractionState: null 
}

function polling(state = {...defaultState}, action) {
    switch (action.type) {
        case 'ON_UPDATE_POLLING_QUESTION_FULFILLED': {
            if (!action.payload) {
                return state;
            }
            if (action.payload.QuestionId === '00000000000000000000000000000000')
            {
                return {
                    ...defaultState,
                    InteractionState: state.InteractionState
                };
            }

            let newFields = [...action.payload.Fields];
            newFields.sort((field1, field2) => field1.FieldOrder - field2.FieldOrder);
            return {
                ...defaultState,
                ...action.payload,
                Fields: newFields,
                NewUserResponses: action.payload.UserResponses,
                InteractionState: state.InteractionState
            };
        }
        case 'ON_SET_POLLING_QUESTION_RESPONSE_FULFILLED': {
            if (!action.payload || !action.payload.Responses) {
                return state;
            }
            return {
                ...state,
                UserResponses: action.payload.Responses,
                NewUserResponses: action.payload.Responses
            };
        }
        case 'SET_NEW_POLLING_QUESTION_RESPONSE_FULFILLED': {
            if (!action.payload || !action.payload.NewUserResponses) {
                return state;
            }
            return {
                ...state,
                NewUserResponses: action.payload.NewUserResponses
            };
        }
        case 'ON_INTERACTION_STATE_UPDATED_FULFILLED': {
            if (!action.payload || !action.payload.ShowGuestInteractionState)
            {
                return state;
            }
            return {
                ...state,
                InteractionState: action.payload.ShowGuestInteractionState
            };
        }
        case 'ON_PEER_CONNECTION_DISCONNECT':
        case 'ON_LEAVE_SHOW_FULFILLED':
        case 'ON_INTERACTION_STATE_DISABLED_FULFILLED': {
            return {
                ...state,
                InteractionState: null
            };
        }
        default:
            return state;
    }
}

export default polling;