let GSocket = undefined;
let searchTimeout = undefined;

export function searchUsers(term) {
	return (dispatch) => {
        // eslint-disable-next-line
        const isEmail = term.match(new RegExp('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}'));
        if (isEmail) {
            GSocket.websocket.publish('SearchContacts', {Name: '', Email: term});
        } else {
		    GSocket.websocket.publish('SearchContacts', {Name: term, Email: ''});
        }
		dispatch({type: 'ON_SEARCH_USERS_PENDING'});
	};
}


const userSearchMiddleware = store => next => async action => {
    if (!GSocket) {
		GSocket = (await import('../../Utilities/Gnural/websockets')).default;
    }
    switch(action.type) {
    case 'SET_CONTACTS_SEARCH_TERM': {
        clearTimeout(searchTimeout);
        if (!action.payload) {
            return next(action);
        }
        searchTimeout = setTimeout(() => {store.dispatch(searchUsers(action.payload))}, 400);
        return next(action);
    }
    case 'ON_ADD_CONTACT_FULFILLED': {
        if (action.payload && action.payload.UserId) {
            GSocket.websocket.publish('GetAddressBook', {UserId: action.payload.UserId});
        }
        next(action);
        return;
    }
    case 'ON_REMOVE_CONTACT_FULFILLED': {
        if (action.payload && action.payload.UserId) {
            GSocket.websocket.publish('GetAddressBook', {UserId: action.payload.UserId});
        }
        next(action);
        return;
    }
    case 'ON_GET_ADDRESS_BOOK_FULFILLED': {
        if (action.payload && action.payload.UserShortDescrs) {
            action.payload.UserShortDescrs.forEach(user => {
                GSocket.websocket.publish('GetAvatar', {UserId: user.UserId});
            });
        }
        return next(action);
    }
    case 'ON_SEARCH_USERS_FULFILLED': {
        if (action.payload && action.payload.Lst) {
            action.payload.Lst.forEach(user => {
                GSocket.websocket.publish('GetAvatar', {UserId: user.UserId});
            });
        }
        return next(action);
    }
    default:
        return next(action);
    }
}

export default userSearchMiddleware;