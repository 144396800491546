import React from 'react';
import './PermissionScreen.css';

class PermissionScreen extends React.Component {
    render() {
        return (
            <div className={'MediaPermissionWrapperOuter BrushedMetalGradient'}>
                <div className={'MediaPermissionWrapperInner NoiseBackground'}>
                    <div className={'Primary Error'}>No Media Permission Given</div>
                    <div className={'Secondary'}>Please allow this Website to use your Webcam and Microphone and Refresh this Page</div>
                </div>
            </div>
        )
    }
}

export default PermissionScreen;