import React from 'react';

function SettingsMenuPresentation(props) {
	function precomputedOnUpdateSelectGeneric(selectType) {
		return (event) => {props.onUpdateSelectGeneric(selectType, event);};
	}
	return (
		<div className='SettingsMenuPresentationWrapperOuter NoiseBackground'>
			<div className='SettingsMenuPresentationWrapperInner'>
				<select value={(props.currentVideoInput ? props.currentVideoInput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('videoInputList')}>
					<option value={undefined} disabled>- Select Video Input Device -</option>
					{props.videoInputList.map(input => {
						return <option value={input.deviceId} key={input.deviceId}>{input.label}</option>;
					})}
				</select>
				<select value={props.currentVideoResolution ? props.currentVideoResolution.friendlyName : ''} onChange={props.onUpdateVideoResolution}>
					<option value={undefined} disabled>- Select Video Resolution -</option>
					{props.resolutionList.map(resolution => {
						return <option value={resolution.friendlyName} key={'Input-Selector-' + resolution.friendlyName}>{resolution.friendlyName}</option>;
					})}
				</select>
				<select value={(props.currentAudioInput ? props.currentAudioInput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioInputList')}>]
					<option value={undefined} disabled>- Select Audio Input Device -</option>
					{props.audioInputList.map(input => {
						return <option value={input.deviceId} key={input.deviceId}>{input.label}</option>;
					})}
				</select>
				<select value={(props.currentAudioOutput ? props.currentAudioOutput.deviceId : undefined)} onChange={precomputedOnUpdateSelectGeneric('audioOutputList')}>
					<option value={undefined} disabled>- Select Audio Output Device -</option>
					{props.audioOutputList.map(output => {
						return <option value={output.deviceId} key={output.deviceId}>{output.label}</option>;
					})}
				</select>
				<div className='SettingsMenuButtonWrapper'>
					<div className={'MediaRefreshButton TransparentButton RedHaze' + (props.isMuted ? ' active' : '')} onClick={props.onToggleLocalMediaStreamMute}>Mute</div>
					<div className={'MediaRefreshButton TransparentButton BlueHaze'} onClick={props.onRefreshLocalMedia}> Refresh </div>
				</div>
				{
					<div className={'SettingsMenuButtonWrapper'}>
						<div className={'MediaRefreshButton TransparentButton BlueHaze ' + (props.autoGainControl ? 'active' : '')} onClick={props.onToggleAutoGainControl}>Automatic Gain</div>
						<div className={'MediaRefreshButton TransparentButton BlueHaze ' + (props.voiceOptimization ? 'active' : '')} onClick={props.onToggleVoiceOptimization}>Voice Optimization</div>
					</div>
				}
				{(props.isChrome || props.isEdge ?
					<div className={'SettingsMenuButtonWrapper'}>
						<div className={'MediaRefreshButton TransparentButton BlueHaze ' + (props.useSoftwareEncoding ? 'active' : '')} onClick={props.onToggleUseSoftwareEncoding}>Software Encoding</div>
						<div className={'MediaRefreshButton'}></div>
					</div>
					: null)}
				{(props.UserId ? 
					/*<div className='AutoAnswerWrapper'>
						<input type='checkbox' name='AutoAnswer' onChange={props.onToggleAutoAnswer} value='Auto Answer' checked={props.autoAnswer}/>
						<label htmlFor='AutoAnswer'>Auto Answer</label>
					</div>*/
					<div className={'SettingsMenuButtonWrapper'}>
						<div className={'MediaRefreshButton TransparentButton BlueHaze ' + (props.autoAnswer ? 'active': '')} onClick={props.onToggleAutoAnswer}>Auto Answer</div>
						<div className={'MediaRefreshButton'}/>
					</div>
				: <div className={'SettingsMenuButtonWrapper'}></div>)}
			</div>
		</div>
	);
}

export default SettingsMenuPresentation;