import React from 'react';
import { connect } from 'react-redux';
import LoginPanel from './LoginPanel';
import MediaPanel from './MediaPanel';
import SignupPanel from './SignupPanel';
import IncomingCallPanel from './IncomingCallPanel';
import OutgoingCallPanel from './OutgoingCallPanel';
import InformationPanelHeader from './InformationPanelHeader';
import RemoteVideoPanel from './RemoteVideoPanel';
import EditProfilePanel from './EditProfilePanel';
import JoinShowPanel from './JoinShowPanel';
import ShowButton from './ShowButton';
import ContactsDirectory from './ContactsDirectory';
import CenterDivide from './CenterDivide';
import ChatWindow from './ChatWindow';
import Polling from '../Polling';
import { getContextFromURL, getShowNameFromURL } from '../../Utilities/Gnural/SessionInformation';
import { setCurrentInformationPanel } from '../../Redux/Actions/userinterfaceActions';
import { setJoinShowField } from '../../Redux/Actions/showActions';
import { setContext } from '../../Redux/Actions/callActions';
import '../../Utilities/Gnural/LocalMediaHandler';
import './Main.css';
import ToggleChatWindowButton from './ToggleChatWindowButton';
import InteractionPanel from './InteractionPanel';

function mapStateToProps(store) {
    return {
        currentInformationPanel: store.userinterface.currentInformationPanel,
        isMobileDevice: store.sessionInformation.isMobileDevice,
        UserId: store.user.UserId,
        CallState: store.call.CallState,
        CallinState: store.callin.CallState,
        inShow: store.callin.inShow,
        isSafari: store.sessionInformation.isSafari,
        isChatWindowActive: store.chat.isChatWindowActive,
        isInteractionEnabled: store.polling.InteractionState ? true : false,
        DispName: store.user.DispName,
        QuestionId: store.polling.QuestionId
    }
}

class Main extends React.Component {
    constructor(props) {
        super(props);

        this.displayCurrentInformationPanel = this.displayCurrentInformationPanel.bind(this);
        this.setProperDisplay = this.setProperDisplay.bind(this);
    }

    componentDidMount() {
        if (this.props.isSafari) {
            //var ignore = import('./SafariOverride.css');
        }
        
		let context = getContextFromURL();
        let showName = getShowNameFromURL();

        if (context) {
			this.props.dispatch(setContext(context));
		}
		if (showName) {
			this.props.dispatch(setJoinShowField('ShowName', showName));
			this.props.dispatch(setCurrentInformationPanel('JoinShow'));
		}
    }

    componentDidUpdate(nextProps) {
        if (nextProps.isSafari) {
            var ignore = import('./SafariOverride.css');
        }
    }

    displayCurrentInformationPanel() {
        switch (this.props.currentInformationPanel) {
        case 'Media': {
            return (
                <React.Fragment>
                    <MediaPanel/>
                    <div className={'HorizontalRule'}></div>
                    {this.props.UserId && !this.props.inShow &&
                     this.props.CallinState !== 'OffAir' && this.props.CallinState !== 'OnAir'  ?
                        <ContactsDirectory/> :
                        (this.props.inShow && this.props.QuestionId ? 
                            <Polling/>
                            :
                            <div className={'MediaSettingsFiller BodyFiller NoiseBackground'}/>
                        )
                    }
                    <ShowButton/>
                </React.Fragment>
            );
        }
        case 'Login': {
            return <LoginPanel/>;
        }
        case 'Signup': {
            return <SignupPanel/>;
        }
        case 'Incoming Call': {
            return <IncomingCallPanel/>
        }
        case 'Outgoing Call': {
            return <OutgoingCallPanel/>
        }
        case 'JoinShow': {
            return <JoinShowPanel/>
        }
        case 'EditProfile': {
            return <EditProfilePanel/>;
        }
        default:
            return <MediaPanel/>
        }
    }

    setProperDisplay() {
        if (this.props.isMobileDevice) {
            if (this.props.currentInformationPanel === 'Video') {
                return(
                    <div className='MainMobile'>
                        <div className='BodyMobileWrapper'>
                            <RemoteVideoPanel/>
                        </div>
                    </div>
                );
            }
            return (
                <div className='MainMobile'>
                    <div className='BodyMobileWrapper'>
                        <InformationPanelHeader/>
                        {this.displayCurrentInformationPanel()}
                    </div>
                </div>
            );
        } else {
            return (
                <div className='Main BrushedMetalGradient'>
                    <div className='LeftPanelWrapperOuter'>
                        <InformationPanelHeader/>
                        <div className='LeftPanelWrapper'>
                            {this.displayCurrentInformationPanel()}
                        </div>
                    </div>
                    <CenterDivide/>
                    <div className='RightPanelWrapper'>
                        <div className={'RightPanelHeader IndentHeaderWrapper'}>
                            {this.props.DispName ? <div className={'DisplayNameIndicator'}>{this.props.DispName}</div> : null}
                        </div>
                        <div className={'RightPanelBody'}>
                            <RemoteVideoPanel/>
                            <ChatWindow/>
                        </div>
                        <div className={'RightPanelFooter'}>
                            {(this.props.isInteractionEnabled ? <InteractionPanel/> : null)}
                            {(this.props.isChatWindowActive ? <ToggleChatWindowButton/> : null)}
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        return this.setProperDisplay(); 
    }
}

export default connect(mapStateToProps)(Main);

