import React from 'react';
import Logger from '../../Utilities/Logging';

import FramingBars from '../../Assets/Brackets.svg';

const INFO = '<Application/Main/ModifyGuestSection/GuestMediaSection/CustomVideo';

class CustomVideo extends React.Component {
	constructor(props) {
		super(props);

		this.shouldDisplayVideo = this.shouldDisplayVideo.bind(this);
		this.attachSinkId = this.attachSinkId.bind(this);
		this.loadFraming = this.loadFraming.bind(this);
	}

	loadFraming() {
		// Logic for Dynamic Color Bar Scaling Will Go Here...
		if (!this.framingElement) {
			return;
		} else {
			return;
		}
		/*
		const videoWidth = this.videoElement.videoWidth;
		const videoHeight = this.videoElement.videoHeight;
		if (!(videoWidth && videoHeight)) {
			return;
		}
		const videoAspectRatio = (videoHeight/videoWidth)*100;
		*/
	}

	componentDidUpdate() {
		if (!this.videoElement) {
			return;
		}
		this.videoElement.srcObject = this.props.srcObject;
		this.attachSinkId();
		if (this.videoElement.addEventListener) {
			this.videoElement.addEventListener('loadedmetadata', this.loadFraming);
		}
	}

	componentWillUnmount() {
		if(this.videoElement && this.videoElement.removeEventListener) {
			this.videoElement.removeEventListener('loadedmetadata', this.loadFraming);
		}
	}

	shouldDisplayVideo() {
		if (!this.props.srcObject) {
			this.videoElement = {};
			return <div className={this.props.className}/>;
		} else {
			return (
				<React.Fragment>
					<video className={this.props.className} ref={(video) => {this.videoElement = video;}} muted={this.props.muted} autoPlay playsInline/>
					{(this.props.shouldContainFraming ?  <img src={FramingBars} className='VideoFraming' alt='Frames for Positioning Guests'/> : null)}
				</React.Fragment>
			);
		}
	}

	async attachSinkId() {
		if (!this.videoElement || !this.videoElement.setSinkId || this.props.muted || !this.props.currentAudioOutput || !this.props.srcObject) {
			return;
		}
		try {
			await this.videoElement.setSinkId(this.props.currentAudioOutput.deviceId);
		} catch (e) {
			Logger.error(INFO, ' Failed to Attach SinkId: ', e);
		}
	}

	render() {
		return(
			this.shouldDisplayVideo()
		);
	}
}

export default CustomVideo;