import React from 'react';
import { connect } from 'react-redux';
import { openChat, closeChat } from '../../../../Redux/Actions/chatActions';
import ChatTabPresentation from './ChatTabPresentation';

function mapStateToProps(store) {
    return {
        SelectedChatObject: store.chat.SelectedChatObject,
        ChatObjects: store.chat.ChatObjects
    };
}

class ChatTabs extends React.Component {
    constructor(props) {
        super(props);

        this.onOpenChat = this.onOpenChat.bind(this);
    }

    onOpenChat(UserId, DispName) {
        return () => {
            this.props.dispatch(openChat(UserId, DispName));
        }
    }
    
    onCloseChat(UserId) {
        return (event) => {
            event.stopPropagation();
            this.props.dispatch(closeChat(UserId));
        }
    }

    render() {
        return (
            <div className={'ChatTabsWrapper'}>
                {Object.values(this.props.ChatObjects).map(ChatObject => {
                    if (!ChatObject.ShouldDisplay) {
                        return null;
                    }
                    return <ChatTabPresentation
                        key={ChatObject.UserId}
                        DispName={ChatObject.DispName}
                        isSelected={(this.props.SelectedChatObject === ChatObject.UserId ? true : false)}
                        onOpenChat={this.onOpenChat(ChatObject.UserId, ChatObject.DispName)}
                        onCloseChat={this.onCloseChat(ChatObject.UserId)}
                        hasNewMessages={ChatObject.hasNewMessages}
                    />
                })}
            </div>
        );
    }
}

export default connect(mapStateToProps)(ChatTabs);