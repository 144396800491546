import React from 'react';

function LoginPresentation(props) {
	return (
		<form className={'IsolatedInputWrapper'} onSubmit={props.onLoginSubmit}>
			<input
				type='text'
				value={props.loginUsername}
				onChange={props.onLoginUsernameChange}
				placeholder={'Enter your Username...'}
				autoComplete='username'
			/>
			<input
				type='password'
				value={props.loginPassword}
				onChange={props.onLoginPasswordChange}
				placeholder={'Enter your Password...'}
				autoComplete='current-password'
			/>
			<button className='LoginButton TransparentButton GreenHaze Static' type='submit'><div>Login</div></button>
		</form>
	);
}

export default LoginPresentation;