
function userinterface(state={
	openModalID: undefined,
	currentTime: undefined,
	currentInformationPanel: 'Media',
	lastInformationPanel: 'Media',
	currentContactsTab: 'Contacts',
	currentContactsSearchTerm: '',
	currentContactsSelectedUser: {},
	isMobileDevice: false,
	fullscreenRemote: false,
    ShowQueueOverlay: true
}, action) {
	switch (action.type) {
	case 'ATTEMPT_MODAL_OPEN': {
		if (state.openModalID) {
			return state;
		}
		return {...state, openModalID: action.payload};
	}
	case 'ATTEMPT_MODAL_CLOSE': {
		if (action.payload !== state.openModalID) {
			return state;
		}
		return {...state, openModalID: undefined};
	}
	case 'UPDATE_CURRENT_TIME': {
		if (action.payload) {
			return {...state, currentTime: action.payload};
		}
		return state;
	}
	case 'UPDATE_CURRENT_INFORMATION_PANEL': {
		if (!action.payload) {
			return state;
		}
		return {...state, currentInformationPanel: action.payload, lastInformationPanel: state.currentInformationPanel};
	}
	case 'LOGIN_FULFILLED': {
		if (!action.payload || !action.payload.UserId) {
			return state;
		}
		return {...state, currentInformationPanel: 'Media'};
	}
	case 'ON_INCOMING_CALL_PENDING': {
		if (!action.payload) {
			return state;
		}
		return {...state, lastInformationPanel: state.currentInformationPanel, currentInformationPanel: 'Incoming Call'};
	}
	case 'ON_INCOMING_CALL_FULFILLED': {
		if (state.isMobileDevice) {
			return {...state, currentInformationPanel: 'Video'};
		}
		return {...state, currentInformationPanel: 'Media'};
	}
	case 'ON_INCOMING_CALL_REJECTED': {
		return {...state, currentInformationPanel: state.lastInformationPanel, lastInformationPanel: state.lastInformationPanel};
	}
	case 'ON_DROP_USER_ID_FULFILLED': {
		return {...state, currentInformationPanel: state.lastInformationPanel, lastInformationPanel: state.lastInformationPanel};
	}
	case 'DISCONNECT_PEER_REMOTE': {
		if(state.isMobileDevice) {
			return {...state, currentInformationPanel: state.lastInformationPanel, lastInformationPanel: state.lastInformationPanel};
		}
		return state;
	}
	case 'ON_ENTER_SHOW_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		if (state.isMobileDevice) {
			return {...state, currentInformationPanel: 'Video', lastInformationPanel: 'Media'};
		}
		return {...state, currentInformationPanel: 'Media', lastInformationPanel: 'Media'};
	}
	case 'SET_BROWSER_INFORMATION': {
		if (!action.payload) {
			return state;
		}
		return {...state, isMobileDevice: action.payload.isMobileDevice};
	}
	case 'SET_CONTACTS_SEARCH_TERM': {
		return {...state, currentContactsSearchTerm: action.payload, currentContactsTab: (action.payload ? 'Search' : 'Contacts')};
	}
	case 'SET_CONTACTS_ACTIVE_TAB': {
		if (!action.payload) {
			return state;
		}
		return {...state, currentContactsTab: action.payload};
	}
	case 'SET_CONTACTS_SELECTED_USER': {
		if(!action.payload || !action.payload.UserId) {
			return state;
		}
		return {...state, currentContactsSelectedUser: action.payload};
	}
	case 'ON_CALL_USER_ID_PENDING': {
		if (!action.payload || !action.payload.UserId) {
			return state;
		}
		return {...state, currentInformationPanel: 'Outgoing Call'};
	}
	case 'ON_DROP_USER_ID_PENDING': {
		return {...state, currentInformationPanel: 'Media'};
	}
	case 'ON_CONTEXT_CHANGED': {
		if (!action.payload) {
			return state;
		}
		if (state.isMobileDevice) {
			return {...state, currentInformationPanel: 'Video', lastInformationPanel: 'Media'};
		}
		return {...state, currentInformationPanel: 'Media', lastInformationPanel: 'Media'};
	}
	case 'ON_CONTEXT_DENY': {
		return {...state, currentInformationPanel: 'Media'};
	}
	case 'TOGGLE_FULLSCREEN_REMOTE': {
		return {
			...state,
			fullscreenRemote: !state.fullscreenRemote
		};
	}
	case 'SET_SHOW_QUEUE_OVERLAY': {
		return {
			...state,
			ShowQueueOverlay: action.payload  || false
		};
	}
	default:
		return state;
	}
}

export default userinterface;