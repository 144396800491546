import React from 'react';

class IncomingCallPresentation extends React.Component {
    render() {
        return (
            <div className='IncomingCallPresentationWrapper FlexColumnWrapper'>
                <div className={'IndentHeaderWrapper'}>
                    <div className=''>{'Call From'}</div>
                    <div className={'CallerText'}>{this.props.CallDispName}</div>
                </div>
                <div className={'IncomingCallInnerWrapper NoiseBackground'}>
                    <img src={this.props.CallerAvatar} onError={this.props.onImageError} alt='Caller-Avatar'/>
                    <div className={'IncomingCallButtonWrapper'}>
                        <div className={'TransparentButton GreenHaze Static'} onClick={this.props.onAcceptIncomingCall}>Accept</div>
                        <div className={'TransparentButton RedHaze Static'} onClick={this.props.onDenyIncomingCall}>Reject</div>
                    </div>
                </div>
                <div className={'HorizontalRule'}/>
                <div className={'BodyFiller NoiseBackground'}/>
                <div className={'FooterFiller'}/>
            </div>
        );
    }
}

export default IncomingCallPresentation;