import React from 'react';

class UserListPresentation extends React.Component {
    constructor(props) {
        super(props);

        this.onImageError = this.onImageError.bind(this);
    }

    onImageError(event) {
        if (!event || !event.target) {
            return;
        }
        event.target.src = this.props.DefaultAvatar;
    }

    render() {
        return(
            <div className='UserList'>
                {this.props.UserList.map(user => {
                    return (
                        <div
                            className={'UserCard ' + 
                                (user.UStatus === 'OffLine' ? 'Offline' : 'Online') +
                                ((this.props.selectedUser || {}).UserId === user.UserId ? ' Selected' : '')
                            }
                            key={'UserList-'+user.UserId}
                            onClick={this.props.onSetSelectedUser(user)}
                        >
                            <div className={'UserCardContent'}>
                                <div>
                                    <img className={'ContactAvatar'} src={user.Avatar} alt={user.DispName+'-Avatar'} onError={this.onImageError}/>
                                </div>
                                <div>
                                    <div>{user.DispName}</div>
                                    <div>{user.CompanyName}</div>
                                </div>
                            </div>
                            {(this.props.selectedUser || {}).UserId === user.UserId ? 
                                <div className={'ContactsButtonWrapper'}>
                                    <div className={'TransparentButton GreenHaze Static ' + (user.UserId === this.props.LocalUserId || user.UStatus === 'OffLine' || this.props.RTCCallState === 'ACTIVE' ? 'inactive' : '')} onClick={this.props.onCallUser}>Call</div>
                                    <div className={'TransparentButton GreenHaze Static ' + (user.UserId === this.props.LocalUserId || user.UStatus === 'OffLine' ? 'inactive' : '')} onClick={this.props.onMessageUser}>Message</div>
                                    {this.props.displayProperButton()}
                                </div>
                                :
                                null
                            }
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default UserListPresentation;