import React from 'react';
import { connect } from 'react-redux';
import { setCurrentInformationPanel } from '../../../Redux/Actions/userinterfaceActions';
import { logOutUser } from '../../../Redux/Actions/loginActions';

import './InformationPanelHeader.css';

function mapStateToProps(store) {
    return {
        currentInformationPanel: store.userinterface.currentInformationPanel,
        Username: store.user.Username,
        inShow: store.callin.inShow,
        inCall: store.call.CallState
    }
}

class InformationPanelHeader extends React.Component {
    constructor(props) {
        super(props);

        this.displayProperHeader = this.displayProperHeader.bind(this);
        this.onLogOutUser = this.onLogOutUser.bind(this);
    }

    onSetCurrentInformationPanel(panelName) {
        return () => {
            this.props.dispatch(setCurrentInformationPanel(panelName));
        }
    }

    onLogOutUser() {
        this.props.dispatch(logOutUser());
    }

    displayProperHeader() {

        if (this.props.inShow || this.props.inCall) {
            return (
                <div className={'InformationPanelHeader'}></div>
            );
        }
        if (this.props.currentInformationPanel === 'Media' && !this.props.Username) {
            return (
                <div className={'InformationPanelHeader'}>
                    <div className={'TransparentButton BlueHaze '} onClick={this.onSetCurrentInformationPanel('Login')}><div>Login</div></div>
                    <div className={'TransparentButton BlueHaze '} onClick={this.onSetCurrentInformationPanel('Signup')}><div>Signup</div></div>
                </div>
            );
        }
        
        if (this.props.currentInformationPanel === 'Media') {
            return (
                <div className={'InformationPanelHeader'}>
                    <div className={'TransparentButton RedHaze'} onClick={this.onLogOutUser}><div>Log-Out</div></div>
                    <div className={'TransparentButton BlueHaze '} onClick={this.onSetCurrentInformationPanel('EditProfile')}><div>Edit Profile</div></div>
                </div>
            );
        }

        if (this.props.currentInformationPanel === 'Incoming Call' || this.props.currentInformationPanel === 'Outgoing Call') {
            return (
                <div className={'InformationPanelHeader'}></div>
            );
        }

        return (
            <div className={'InformationPanelHeader'}>
                <div className={'TransparentButton BlueHaze'} onClick={this.onSetCurrentInformationPanel('Media')}><div>Back</div></div>
            </div>
        );
    }

    render() {
        return this.displayProperHeader();
    }
}

export default connect(mapStateToProps)(InformationPanelHeader);
