//import GnuralRTC from '../../Utilities/Gnural/GnuralRTC';
//import GSocket from '../../Utilities/Gnural/websockets';
import Logger from '../../Utilities/Logging';
//import RemoteMediaHolder from '../../Utilities/Gnural/RemoteMediaHolder';

const INFO = 'Redux/Middleware/webRTCMiddleware';

let GSocket = undefined;
let GnuralRTC = undefined;
let RemoteMediaHolder = undefined;
let LocalMediaHandler = undefined;

const DisconnectPeer = (store, next, action) => {
	if (
		!action.payload ||
		!action.payload.PeerId
	) {
		Logger.error(INFO, 'Invalid Parameters to Disconect Show Guest');
		if (next) {
			next(action);
		}
		return;
	}
	const state = store.getState();
	if (state.media.remotePeerID) {
		try {
			GnuralRTC.Instance.removePeerConnection(state.media.remotePeerID, null);
		} catch (e) {
			Logger.error(INFO, 'Failed to Remove Peer Connection: ', e);
		}
		GSocket.websocket.publish('disconnectpeer', {recipient: action.payload.PeerId});
		GSocket.websocket.publish('leavecontext', {});
		RemoteMediaHolder.setRemoteStream(undefined);
	}
	if (next) {
		next(action);
	}
	return;
};

const sendBrowserInfo = (store, PeerGuid, ReqGuid) => {
	const state = store.getState();
	GSocket.websocket.publish('BrowserInfoResponce', {
		PeerGuid: PeerGuid,
		ReqGuid: ReqGuid,
		BrowserName: state.sessionInformation.BrowserName,
		BrowserVersion: state.sessionInformation.BrowserVer,
		OsVersion: state.sessionInformation.OsVer,

		CamerAccess: state.sessionInformation.VideoPermission,
		MicAccess: state.sessionInformation.AudioPermission,

		CameraList: (state.media.videoInputList || []).map(camera => {return (camera || {}).label}),
		MicList: (state.media.audioInputList || []).map(mic => {return (mic || {}).label}),
		SpkrList: (state.media.audioOutputList || []).map(speaker => {return (speaker || {}).label}),
		CameraResList: (state.media.currentVideoInput && state.media.currentVideoInput.deviceId ?
			((state.media.videoResolutionObject || {})[state.media.currentVideoInput.deviceId] || []).map(resolutions => {return (resolutions || {}).friendlyName}) : []),
		
		CurrentCamera: (state.media.currentVideoInput || {}).label,
		CurrentRes: (state.media.currentVideoResolution || {}).friendlyName,
		CurrentMic: (state.media.currentAudioInput || {}).label,
		CurrentSpkr: (state.media.currentAudioOutput || {}).label
	});	
}

const webRTCMiddleware = store => next => async action => {
	if (!GSocket) {
		GSocket = (await import('../../Utilities/Gnural/websockets')).default;
	}
	if (!GnuralRTC) {
		GnuralRTC = (await import('../../Utilities/Gnural/GnuralRTC')).default;
	}
	if (!RemoteMediaHolder) {
		RemoteMediaHolder = (await import('../../Utilities/Gnural/RemoteMediaHolder')).default;
	}
	if (!LocalMediaHandler) {
		LocalMediaHandler = (await import('../../Utilities/Gnural/LocalMediaHandler')).default;
	}
	switch (action.type) {
	case 'DISCONNECT_PEER_PENDING': {
		DisconnectPeer(store, next, action);
		return;
	}
	case 'DISCONNECT_PEER_REMOTE': {
		if (!action.payload || !action.payload.ShowGuestGuid) {
			next(action);
			return;
		}
		const state = store.getState();
		if (state.call.PeerId !== action.payload.PeerId) {
			next(action);
			return;
		}
		DisconnectPeer(store, next, action);
		return;

	}
	case 'RECONNECT_PEER_PENDING': {
		if (
			!action.payload ||
			!action.payload.Context ||
			!action.payload.Recipient ||
			!action.payload.Sender
		) {
			next({
				type: 'RECONNECT_PEER_REJECTED',
				payload: {error: 'Invalid Parameters for Reconnecting Peer'}
			});
			return;
		}
		const state = store.getState();
		if (state.media.remotePeerID) {
			try {
				GnuralRTC.Instance.removePeerConnection(state.media.remotePeerID, null);
			} catch (e) {
				Logger.error(INFO, 'Failed to Remove Peer Connection: ', e);
			}
			RemoteMediaHolder.setRemoteStream(undefined);
			GSocket.websocket.publish('reconnect', {
				Context: action.payload.Context,
				Recipient: action.payload.Recipient,
				Sender: action.payload.Sender
			});
		}
		next(action);
		return;
	}
	case 'SET_NEW_LOCAL_MEDIA_STREAM': {
		RemoteMediaHolder.setRemoteStream(undefined);
		const state = store.getState();
		if (state.call.PeerId) {
			sendBrowserInfo(store, state.callin.CookieGuid, state.call.PeerId);
		}
		next(action);
		return;
	}
	case 'REFRESH_LOCAL_MEDIA': {
		LocalMediaHandler.refreshLocalMedia();
		next(action);
		return;
	}
	case 'ON_RECONNECT_FULFILLED': {
		const state = store.getState();
		if (!action || !action.payload || !action.payload.Sender || state.call.PeerId !== action.payload.Sender) {
			return next(action);
		}
		LocalMediaHandler.refreshLocalMedia();
		next(action);
		return;
	}
	case 'LOGIN_FULFILLED': {
		GSocket.websocket.publish('getrelayservers', {});
		return next(action);
	}
	case 'GET_RELAY_SERVERS_FULFILLED': {
		if (!action.payload || !action.payload.Lst) {
			next(action);
			return;
		}
		window.RelayServers = action.payload.Lst;
		next(action);
		return;
	}
	case 'ON_DISCONNECT_SELF_PENDING': {
		const peers = GnuralRTC.Instance.getRemotePeers();
		peers.forEach((peer) => {
			GnuralRTC.Instance.removePeerConnection(peer.PeerId, null);
			GSocket.websocket.publish('DisconnectPeer', { recipient: peer.PeerId})
		});
		GSocket.websocket.publish('leavecontext');
		next(action);
		return;
	}
	case 'ON_INCOMING_CALL_FULFILLED': {
		if (!action.payload ||
			!action.payload.PeerId ||
			!action.payload.Context ||
			!action.payload.UserId ||
			!action.payload.DispName) {
			next(action);
			return;
		}
		GnuralRTC.Instance.CreatePeerConnection(action.payload)
		GnuralRTC.Instance.changeContext(action.payload.Context);
		next(action);
		return;
	}
	case 'ON_INCOMING_CALL_PENDING': {
		const state = store.getState();
		if (state.call.CallState === 'ACTIVE' || state.callin.inShow || state.call.CallState === 'PENDING_INCOMING'
			|| state.call.CallState === 'PENDING_OUTGOING') {
			if (action.payload && action.payload.PeerId) {
        		GSocket.websocket.publish('DenyContext', {recipient: action.payload.PeerId});
			}
			return;
		}
		if (!action.payload ||
			!action.payload.PeerId ||
			!action.payload.Context ||
			!action.payload.UserId ||
			!action.payload.DispName ||
			!state.call.AutoAnswer) {
			next(action);
			if (action.payload && action.payload.UserId) {
				GSocket.websocket.publish('GetAvatar', {UserId: action.payload.UserId});
			}
			return;
		}
		GnuralRTC.Instance.CreatePeerConnection(action.payload)
		GnuralRTC.Instance.changeContext(action.payload.Context);
		next({type: 'ON_INCOMING_CALL_FULFILLED', payload: {...action.payload}});
		return;
	}
	case 'ON_SET_CONTEXT_PENDING': {
		if (!action.payload) {
			next(action);
			return;
		}
		GnuralRTC.Instance.changeContext(action.payload);
		return next(action);
	}
	case 'WEBSOCKET_CONNECTION_FULFILLED': {
		LocalMediaHandler.refreshLocalMedia();
		/*let context = getContextFromURL();
		if (context) {
			GnuralRTC.Instance.changeContext(context);
		}*/
		next({type: action.type, payload: {...action.payload}});
		return; 
	}
	case 'ON_WEBRTC_STATS': {
		const state = store.getState();
		if (!action.payload || !state.media.remotePeerID) {
			return next(action);
		}
		GSocket.websocket.publish('ObjectToPeer', {RecipientId: state.media.remotePeerID, SenderId: state.user.PeerId, MessageObject: {...action.payload, ObjectType: 'Stats'}});
		next(action);
		return;
	}
	case 'ON_REQUEST_BROWSER_INFO_FULFILLED': {
		if (!action.payload || !action.payload.PeerGuid || !action.payload.ReqGuid) {
			return next(action);
		}
		sendBrowserInfo(store, action.payload.PeerGuid, action.payload.ReqGuid);
		return next(action);
	}
	case 'TOGGLE_LOCAL_MEDIA_STREAM_MUTE': {
		const state = store.getState();
		GnuralRTC.Instance.muteAudio(!state.media.isMuted);
		return next(action);
	}
	default: {
		next(action);
	}
	}
};

export default webRTCMiddleware;