import React from 'react';
import LoginPresentation from './LoginPresentation';
import { connect } from 'react-redux';
import { logInUser } from '../../../Redux/Actions/loginActions';

import './LoginPanel.css';

function mapStateToProps(store) {
	return {
		user: {
			loginStatus: store.user.loginStatus,
			error: {
				loginError: store.user.error.loginError
			}
		}
	};
}

class LoginPanel extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			loginUsername: '',
			loginPassword: '',
		};
		this.onLoginSubmit = this.onLoginSubmit.bind(this);
		this.onLoginUsernameChange = this.onLoginUsernameChange.bind(this);
		this.onLoginPasswordChange = this.onLoginPasswordChange.bind(this);
	}

	onLoginSubmit(event) {
		event.preventDefault();
		this.props.dispatch(logInUser(this.state.loginUsername, this.state.loginPassword));
	}

	onLoginUsernameChange(event) {
		this.setState({
			loginUsername: event.target.value
		});
	}

	onLoginPasswordChange(event) {
		this.setState({
			loginPassword: event.target.value
		});
	}

	render() {
		return ( 
			<div className='FlexColumnWrapper'>
				<div className='IndentHeaderWrapper'>
					<div>Login</div>
					<div className='Error'>{this.props.user.error.loginError}</div>
				</div>
				<div className='IsolatedInputWrapper LoginFormWrapper'>
					<LoginPresentation {...{
						onLoginSubmit: this.onLoginSubmit,
						onLoginUsernameChange: this.onLoginUsernameChange,
						onLoginPasswordChange: this.onLoginPasswordChange,
						loginUsername: this.state.loginUsername,
						loginPassword: this.state.loginPassword,
					}}/>
				</div>
                <div className={'HorizontalRule'}></div>
                <div className={'BodyFiller NoiseBackground'}/>
				<div className={'FooterFiller'}/>
			</div>
		);
	}
}

export default connect(mapStateToProps)(LoginPanel);