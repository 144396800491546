
function media(state={
	audioInputList: [],
	audioOutputList: [],
	videoInputList: [],
	videoResolutionObject: {},
	currentAudioInput: {},
	currentAudioOutput: {},
	currentVideoInput: {},
	currentVideoResolution: {},
	autoGainControl: true,
	voiceOptimization: true,
	useSoftwareEncoding: false,
	localMediaStreamID: '',
	remoteMediaStreamID: '',
	remotePeerID: 0,
	isProcessing: false,
	isMuted: false
}, action) {
	switch(action.type) {
	case 'SET_MEDIA_INFORMATION': {
		return {...state,
			audioInputList: action.payload.audioInputList,
			audioOutputList: action.payload.audioOutputList,
			videoInputList: action.payload.videoInputList,
			videoResolutionObject: action.payload.videoResolutionObject,
			currentAudioInput: action.payload.currentAudioInput,
			currentAudioOutput: action.payload.currentAudioOutput,
			currentVideoInput: action.payload.currentVideoInput,
			currentVideoResolution: action.payload.currentVideoResolution,
			autoGainControl: action.payload.autoGainControl,
			voiceOptimization: action.payload.voiceOptimization,
			useSoftwareEncoding: action.payload.useSoftwareEncoding
		};
	}
	case 'SET_NEW_MEDIA_DEVICE': {
		if (!action.payload || !action.payload.mediaType || !action.payload.mediaObject) {
			return state;
		}
		switch(action.payload.mediaType) {
		case 'videoInputList': {
			return {...state, currentVideoInput: action.payload.mediaObject};
		}
		case 'audioInputList': {
			return {...state, currentAudioInput: action.payload.mediaObject};
		}
		case 'audioOutputList': {
			return {...state, currentAudioOutput: action.payload.mediaObject};
		}
		case 'videoResolutionObject': {
			return {...state, currentVideoResolution: action.payload.mediaObject};
		}
		default:
			return state;
		}
	}
	case 'UPDATE_VIDEO_RESOLUTION_OBJECT': {
		if (!action.payload || !action.payload.videoResolutionList || !action.payload.deviceId) {
			return state;
		}
		return {
			...state,
			videoResolutionObject: {
				...state.videoResolutionObject,
				[action.payload.deviceId]: action.payload.videoResolutionList
			}
		};
	}
	case 'SET_NEW_LOCAL_MEDIA_STREAM': {
		if (!action.payload) {
			return state;
		}
		return {...state, localMediaStreamID: action.payload};
	}
	case 'DISCONNECT_PEER_FULFILLED': {
		return {...state, remoteMediaStreamID: '', remotePeerID: 0};
	}
	case 'ON_REMOTE_STREAM': {
		if (!action.payload ||
			!action.payload.stream ||
			!action.payload.PeerId || 
			!action.payload.stream.id) {
			return {...state, remoteMediaStreamID: '', remotePeerID: 0};
		}
		return {
			...state,
			remoteMediaStreamID: action.payload.stream.id,
			remotePeerID: action.payload.PeerId
		};
	}
	case 'ON_SET_GUEST_DEVICE_FULFILLED': {
		if (!action.payload || !action.payload.PeerGuid ||
			!action.payload.Device || !action.payload.Value || 
			action.payload.ReqGuid !== state.remotePeerID) {
			return state;
		}

		let newDevice;

		switch(action.payload.Device) {
			case 'Camera': {
				state.videoInputList.forEach(input => {
					if (input && input.label === action.payload.Value) {
						newDevice = input;
					}
				});
				if (!newDevice) {
					return state;
				}
				return {...state, currentVideoInput: newDevice};
			}
			case 'CamRes': {
				if (!state.currentVideoInput || !state.currentVideoInput.deviceId || 
					!state.videoResolutionObject || !state.videoResolutionObject[state.currentVideoInput.deviceId]) {
					return state;
				}
				state.videoResolutionObject[state.currentVideoInput.deviceId].forEach(resolution => {
					if (resolution && resolution.friendlyName === action.payload.Value) {
						newDevice = resolution;
					}
				});
				if (!newDevice) {
					return state;
				}
				return {...state, currentVideoResolution: newDevice};
			}
			case 'Mic': {
				state.audioInputList.forEach(input => {
					if (input && input.label === action.payload.Value) {
						newDevice = input;
					}
				});
				if (!newDevice) {
					return state;
				}
				return {...state, currentAudioInput: newDevice};
			}
			case 'Spkr': {
				state.audioOutputList.forEach(input => {
					if (input && input.label === action.payload.Value) {
						newDevice = input;
					}
				});
				if (!newDevice) {
					return state;
				}
				return {...state, currentAudioOutput: newDevice};
			}
			default: 
				return state;
		}
	}
	case 'TOGGLE_LOCAL_MEDIA_STREAM_MUTE': {
		return {
			...state,
			isMuted: !state.isMuted
		};
	}
	case 'TOGGLE_VOICE_OPTIMIZATION': {
		return {
			...state, 
			voiceOptimization: !state.voiceOptimization
		};
	}
	case 'TOGGLE_AUTO_GAIN_CONTROL': {
		return {
			...state, 
			autoGainControl: !state.autoGainControl
		};
	}
	case 'TOGGLE_USE_SOFTWARE_ENCODING': {
		return {
			...state, 
			useSoftwareEncoding: !state.useSoftwareEncoding
		};
	}
	case 'ON_SET_IS_PROCESSING': {
		return {
			...state, 
			isProcessing: action.payload
		};
	}
	default:
		return state;
	}
}

export default media;