import { getConfIdFromURL } from '../../Utilities/Gnural/SessionInformation';
let GSocket = undefined;

const loginMiddleware = store => next => async action => {
	if (!GSocket) {
		GSocket = (await import('../../Utilities/Gnural/websockets')).default;
	}
    switch (action.type) {
    case 'LOGIN_FULFILLED': {
        if (!action.payload || !action.payload.UserId) {
            return next(action);
        }
        GSocket.websocket.publish('GetAvatar', {UserId: action.payload.UserId});
        GSocket.websocket.publish('GetAddressBook', {UserId: action.payload.UserId});
        return next(action);
    }
    case 'WEBSOCKET_CONNECTION_FULFILLED': {
        const ConfId = getConfIdFromURL();
        if (ConfId) {
            GSocket.websocket.publish('FinishSignUp', {ReqId: ConfId});
        }
        return next(action);
    }
    case 'ON_ENTER_SHOW_FULFILLED':
    case 'ON_ENTER_SHOW_EXTERNAL_FULFILLED':
    case 'ON_USER_ALERT_FULFILLED': {
        if (action.payload && action.payload.ShowId) {
            GSocket.websocket.publish('GetCurrentPollingQuestion', {});
        } else {
            let state = store.getState();
            if (state.callin.ShowId) {
                GSocket.websocket.publish('GetCurrentPollingQuestion', {});
            }
        }
        return next(action);
    }
    default:
        return next(action);
    }
}

export default loginMiddleware;