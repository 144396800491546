import GSocket from '../../Utilities/Gnural/websockets';

export function SetNewPollingQuestionResponse(NewUserResponses) {
    return (dispatch) => {
        if (!NewUserResponses || !Array.isArray(NewUserResponses)) {
            dispatch({
                type: 'SET_NEW_POLLING_QUESTION_RESPONSE_REJECTED',
                payload: { Error: 'Invalid Responses to Set New Polling Question Response' }
            });
            return;
        }
        dispatch({
            type: 'SET_NEW_POLLING_QUESTION_RESPONSE_FULFILLED',
            payload: {
                NewUserResponses
            }
        });
    }
}

export function SetPollingQuestionResponse(Responses) {
    return (dispatch) => {
        if (!Responses || !Array.isArray(Responses)) {
            dispatch({
                type: 'SET_POLLING_QUESTION_RESPONSE_REJECTED',
                payload: { Error: 'Invalid Responses to Set Polling Question Response' }
            });
            return;
        }
        GSocket.websocket.publish('SetPollingQuestionResponse', {
            Responses
        });
        dispatch({
            type: 'SET_POLLING_QUESTION_RESPONSE_PENDING',
            payload: {
                Responses
            }
        });
    }
}

export function SetInteractionState(ShowId, ShowGuestGuid, InteractionState) {
    return (dispatch) => {
        if (!ShowId) {
            dispatch({
                type: 'SET_INTERACTION_STATE_REJECTED',
                payload: { Error: 'Invalid ShowId to Set Interaction State' }
            });
            return;
        }
        if (!ShowGuestGuid) {
            dispatch({
                type: 'SET_INTERACTION_STATE_REJECTED',
                payload: { Error: 'Invalid ShowGuestGuid to Set Interaction State' }
            });
            return;
        }
        if (!InteractionState) {
            dispatch({
                type: 'SET_INTERACTION_STATE_REJECTED',
                payload: { Error: 'Invalid Interaction State to Set Interaction State' }
            });
            return;
        }
        const message = {
            ShowId, ShowGuestGuid,
            ShowGuestInteractionState: InteractionState
        };
        GSocket.websocket.publish('SetInteractionState', message);
        dispatch({
            type: 'SET_INTERACTION_STATE_PENDING',
            payload: message
        });
    }
}
