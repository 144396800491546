import React from 'react';
import { connect } from 'react-redux';

import { SetInteractionState } from '../../../Redux/Actions/pollingActions';

import './InteractionPanel.css';

function mapStateToProps(store) {
    return {
        ShowId: store.callin.ShowId,
        CookieGuid: store.callin.CookieGuid,
        InteractionState: store.polling.InteractionState
    };
}

class InteractionPanel extends React.Component {
    constructor(props) {
        super(props);

        this.onSetInteractionState = this.onSetInteractionState.bind(this);
    }


    onSetInteractionState(state) {
        return () => {
            if (state === this.props.InteractionState)
            {
                this.props.dispatch(SetInteractionState(this.props.ShowId, this.props.CookieGuid, 'None'));
                return;
            }
            this.props.dispatch(SetInteractionState(this.props.ShowId, this.props.CookieGuid, state));
        }
    }

    render() {
        return (
            <div className='InteractionButtonWrapper NoiseBackground'>
                <div
                    onClick={this.onSetInteractionState('HandRaise')}
                    className={'InteractionButton BlueHaze' + (this.props.InteractionState === 'HandRaise' ? ' Active' : '')}>
                        <div>?</div>
                </div>
                <div
                    onClick={this.onSetInteractionState('ThumbsUp')}
                    className={'InteractionButton GreenHaze' + (this.props.InteractionState === 'ThumbsUp' ? ' Active' : '')}>
                        <div>✓</div>
                </div>
                <div
                    onClick={this.onSetInteractionState('ThumbsDown')}
                    className={'InteractionButton RedHaze' + (this.props.InteractionState === 'ThumbsDown' ? ' Active' : '')}>
                        <div>✕</div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(InteractionPanel);