import React from 'react';
import { connect } from 'react-redux';
import {
    setChangedAvatar
} from '../../../../Redux/Actions/changedUserActions';
import Logger from '../../../../Utilities/Logging';
import './AvatarHandler.css';

function mapStateToProps(store) {
    return {
        OriginalAvatar: store.usermanagement.OriginalAvatar,
        ChangedAvatar: store.usermanagement.ChangedAvatar,
        ChangedUserId: store.usermanagement.ChangedUser.UserId
    };
}

class AvatarHandler extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            maxWidth: 150,
            maxHeight: 150
        };

        this.canvasRef = React.createRef();
        this.bufferImgRef = React.createRef();
        this.fileInputRef = React.createRef();
        this.paintCanvas = this.paintCanvas.bind(this);
        this.setUploadedImage = this.setUploadedImage.bind(this);
        this.onResetAvatar = this.onResetAvatar.bind(this);
        this.INFO = 'Application/Main/UserManagementPanel/AvatarHandler'
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.ChangedAvatar !== this.props.ChangedAvatar) {
            if (this.fileInputRef && this.fileInputRef.current) {
                this.fileInputRef.current.value = '';
            }

            if (this.bufferImgRef && this.bufferImgRef.current) {
                this.bufferImgRef.current.src = '';
            }
        }
        if (nextProps !== this.props) {

            if (this.fileInputRef && this.fileInputRef.current) {
                this.fileInputRef.current.value = '';
            }

            if (this.bufferImgRef && this.bufferImgRef.current) {
                this.bufferImgRef.current.src = '';
            }

            this.setState({
                maxWidth: this.props.maxWidth || 150,
                maxHeight: this.props.maxHeight || 150
            });

        }
    }

    async paintCanvas() {
        let canvasContext;
        let targetedAspectRatio = this.state.maxWidth/this.state.maxHeight;
        let usableWidth;
        let usableHeight;
        let returnDataURL;

        //await new Promise((resolve) => {setTimeout(2000, resolve())})
        const image = this.bufferImgRef.current;
        
        if (!image) {
            return;
        }

        canvasContext = this.canvasRef.current.getContext('2d'); // Get a 2d Context for painting on the Canvas
        canvasContext.clearRect(0, 0, this.state.maxWidth, this.state.maxHeight); // Clear the Canvas to remove any old Images on it
        
        if (!((image.width/targetedAspectRatio) > image.height)) {
            usableHeight = (image.width/targetedAspectRatio);
            usableWidth = image.width;
        } else {
            usableHeight = image.height;
            usableWidth = image.height*targetedAspectRatio;
        }

        try {
            canvasContext.drawImage(image, ((image.width - usableWidth)/2), ((image.height - usableHeight)/2), usableWidth, usableHeight, 0, 0, this.state.maxWidth, this.state.maxHeight);
        } catch (e) {
            Logger.warn(this.INFO, 'Failed to Draw Image on Canvas!');
            return;
        }
        
        returnDataURL = this.canvasRef.current.toDataURL("image/jpeg"); // Save Canvas to new Image
        this.props.dispatch(setChangedAvatar(returnDataURL));
    }

    setUploadedImage(event) {
        if (!event || !event.target || !event.target.files || !event.target.files[0]) {
            return;
        }
        const IMAGE_TYPE = 'image.*';
        const fileReader = new FileReader();

        if (!event.target.files[0].type.match(IMAGE_TYPE)) {
            Logger.warn(this.INFO, 'Invalid Image Type Selected');
            return;
        }

        function onFileReaderLoad(input) {
            if (!fileReader.result) {
                return;
            }
            this.bufferImgRef.current.onload = this.paintCanvas;
            this.bufferImgRef.current.src = fileReader.result;
        }
        fileReader.onload = onFileReaderLoad.bind(this);
        fileReader.readAsDataURL(event.target.files[0]);
    }

    onResetAvatar() {
        if (this.props.ChangedUserId) {
            this.props.dispatch(setChangedAvatar(this.props.OriginalAvatar));
        }
    }

    render() {
        return(
            <div className='ProfileImageWrapper'>
                <img ref={this.bufferImgRef} style={{'display': 'none'}} alt={'BufferImg'}/>
                <canvas ref={this.canvasRef} style={{'display': 'none'}} width={this.state.maxWidth} height={this.state.maxHeight}/>
                <img id={'ProfileImage'} src={this.props.ChangedAvatar} alt={'Profile'}/>
                <div className='ProfileImageButtonWrapper'>
                    {(this.props.ChangedUserId ? <input ref={this.fileInputRef} style={{'display': 'none'}} id={'AvatarFileInput'} type={'file'} onChange={this.setUploadedImage} accept={'image/*'}/> : null)}
                    {(this.props.ChangedUserId ? <label className={'TransparentButton GreenHaze Static'} htmlFor={'AvatarFileInput'}>Browse</label> : <div className='TransparentButton GreenHaze Static inactive'>Browse</div>)}
                    <div className={'TransparentButton RedHaze Static' + (this.props.ChangedUserId ? '' : 'inactive')} onClick={this.onResetAvatar}>Reset</div>
                </div>
            </div>
        );
    }


}

export default connect(mapStateToProps)(AvatarHandler);