import React from 'react';
import { connect } from 'react-redux';
import {
    setSelectedPassword,
    setSelectedMatchPassword,
    setSelectedEmail,
    setSelectedCompanyName,
    setSelectedFirstName,
    setSelectedLastName,
    setSelectedUserName,
    signupUser
} from '../../../Redux/Actions/changedUserActions';

import './SignupPanel.css';

function mapStateToProps(store) {
    return {
        ChangedPassword: store.usermanagement.ChangedUser.Password || '',
        ChangedMatchPassword: store.usermanagement.ChangedUser.MatchPassword || '',
        ChangedEmail: store.usermanagement.ChangedUser.Email || '',
        ChangedCompany: store.usermanagement.ChangedUser.CompanyName || '',
        ChangedFirstName: store.usermanagement.ChangedUser.FirstName || '',
        ChangedLastName: store.usermanagement.ChangedUser.LastName || '',
        ChangedLocation: store.usermanagement.ChangedUser.Location || '',
        ChangedAvatar: store.usermanagement.ChangedAvatar || '',
        ChangedUserName: (((store.usermanagement.ChangedUser.Channels || [])[0] || {}).UserName || ''),
        UserError: store.usermanagement.UserError || '',
        UserMessage: store.usermanagement.UserMessage || '',
        Hostname: store.websocket.Hostname
    }
}

class SignupPanel extends React.Component {
    constructor(props) {
        super(props);

        this.changeField = this.changeField.bind(this);
        this.onSignup = this.onSignup.bind(this);
    }

    changeField(action) {
        return (event) => {
            if (!event || !event.target) {
                return;
            }
            this.props.dispatch(action(event.target.value));
        }
    }

    onSignup() {
        this.props.dispatch(signupUser(
            this.props.ChangedUserName,
            this.props.ChangedPassword,
            this.props.ChangedMatchPassword,
            this.props.ChangedFirstName,
            this.props.ChangedLastName,
            this.props.ChangedEmail,
            this.props.ChangedCompany,
            this.props.Hostname
        ));
    }

    render() {
        return (
            <div className='FlexColumnWrapper'>
                <div className='IndentHeaderWrapper'>
                    <div>Signup</div>
                    {(this.props.UserError ? <div className='Error'>{this.props.UserError}</div> : null)}
                    {(this.props.UserMessage ? <div className='Message'>{this.props.UserMessage}</div> : null)}
                </div>
                <form className='IsolatedInputWrapper'>
                    <input type='text' onChange={this.changeField(setSelectedUserName)} value={this.props.ChangedUserName} placeholder={'Enter Your Username...'} autoComplete={'username'}/>
                    <input type='text' onChange={this.changeField(setSelectedFirstName)} value={this.props.ChangedFirstName} placeholder={'Enter Your First Name...'} autoComplete={'name'}/>
                    <input type='text' onChange={this.changeField(setSelectedLastName)} value={this.props.ChangedLastName} placeholder={'Enter Your Last Name...'} autoComplete={'family-name'}/>
                    <input type='text' onChange={this.changeField(setSelectedEmail)} value={this.props.ChangedEmail} placeholder={'Enter Your Email...'} autoComplete={'email'}/>
                    <input type='text' onChange={this.changeField(setSelectedCompanyName)} value={this.props.ChangedCompany} placeholder={'Enter Your Company Name...'} autoComplete={'organization'}/>
                    <input type='password' onChange={this.changeField(setSelectedPassword)} value={this.props.ChangedPassword} placeholder={'Enter Your Password...'} autoComplete={'new-password'}/>
                    <input type='password' onChange={this.changeField(setSelectedMatchPassword)} value={this.props.ChangedMatchPassword} placeholder={'Enter Your Password Again...'} autoComplete={'off'}/>
                </form>
                <div className={'HorizontalRule'}/>
                <div className={'BodyFiller NoiseBackground'}/>
                <div className={'SignupButtonWrapper'} onClick={this.onSignup}>
                    <div className={'TransparentButton GreenHaze Static'}>Signup</div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(SignupPanel);