import XSockets from '../XSockets.latest';

export function getShowNameFromURL() {
    let path = document.location.pathname;
    let lasti = path.lastIndexOf('/');
    let firsti = path.indexOf('/');
    let showName;
    if (firsti === lasti) {
        showName = path.substring(firsti + 1);
    }
    else {
        showName = path.substring(firsti + 1, lasti);
    }
    if (showName.indexOf('.') >= 0) {
            showName = '';
    }

    return showName;
}

export function getContextFromURL() {
    return XSockets.Utils.getParameterByName('ctxId');
}

export function getConfIdFromURL() {
    return XSockets.Utils.getParameterByName('ConfId');
}

export function getParamFromURL(param)
{
    return XSockets.Utils.getParameterByName(param);
}


export function AcquireBrowserInfo() {
    var navigator = window.navigator;

    const browserInformationObject= {}
    browserInformationObject.isMobileDevice = !!(/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent || ''));
    browserInformationObject.isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
    browserInformationObject.isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    browserInformationObject.isFirefox = typeof window.InstallTrigger !== 'undefined';
    browserInformationObject.isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
    browserInformationObject.isChrome = !!window.chrome && !browserInformationObject.isOpera;
    browserInformationObject.isIE = !!document.documentMode && !browserInformationObject.isEdge;

    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset;

    // In Opera, the true version is after 'Opera' or after 'Version'
    if (browserInformationObject.isOpera) {
        browserName = 'Opera';
        try {
            fullVersion = navigator.userAgent.split('OPR/')[1].split(' ')[0];
            majorVersion = fullVersion.split('.')[0];
        } catch (e) {
            fullVersion = '0.0.0.0';
            majorVersion = 0;
        }
    }
        // In MSIE, the true version is after 'MSIE' in userAgent
    else if (browserInformationObject.isIE) {
        verOffset = nAgt.indexOf('MSIE');
        browserName = 'IE';
        fullVersion = nAgt.substring(verOffset + 5);
    }
        // In Chrome, the true version is after 'Chrome' 
    else if (browserInformationObject.isChrome) {
        verOffset = nAgt.indexOf('Chrome');
        browserName = 'Chrome';
        fullVersion = nAgt.substring(verOffset + 7);
    }
        // In Safari, the true version is after 'Safari' or after 'Version' 
    else if (browserInformationObject.isSafari) {
        verOffset = nAgt.indexOf('Safari');
        browserName = 'Safari';
        fullVersion = nAgt.substring(verOffset + 7);

        if ((verOffset = nAgt.indexOf('Version')) !== -1) {
            fullVersion = nAgt.substring(verOffset + 8);
        }
    }
        // In Firefox, the true version is after 'Firefox' 
    else if (browserInformationObject.isFirefox) {
        verOffset = nAgt.indexOf('Firefox');
        browserName = 'Firefox';
        fullVersion = nAgt.substring(verOffset + 8);
    }

        // In most other browsers, 'name/version' is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);

        if (browserName.toLowerCase() === browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }

    if (browserInformationObject.isEdge) {
        browserName = 'Edge';
        // fullVersion = navigator.userAgent.split('Edge/')[1];
        fullVersion = parseInt(navigator.userAgent.match(/Edge\/(\d+).(\d+)$/)[2], 10).toString();
    }

    browserInformationObject.BrowserName = browserName;
    browserInformationObject.BrowserVer = fullVersion;

    var os = "unknown";
    var clientStrings = [{
        s: 'Windows 10',
        r: /(Windows 10.0|Windows NT 10.0)/
    }, {
        s: 'Windows 8.1',
        r: /(Windows 8.1|Windows NT 6.3)/
    }, {
        s: 'Windows 8',
        r: /(Windows 8|Windows NT 6.2)/
    }, {
        s: 'Windows 7',
        r: /(Windows 7|Windows NT 6.1)/
    }, {
        s: 'Windows Vista',
        r: /Windows NT 6.0/
    }, {
        s: 'Windows Server 2003',
        r: /Windows NT 5.2/
    }, {
        s: 'Windows XP',
        r: /(Windows NT 5.1|Windows XP)/
    }, {
        s: 'Windows 2000',
        r: /(Windows NT 5.0|Windows 2000)/
    }, {
        s: 'Windows ME',
        r: /(Win 9x 4.90|Windows ME)/
    }, {
        s: 'Windows 98',
        r: /(Windows 98|Win98)/
    }, {
        s: 'Windows 95',
        r: /(Windows 95|Win95|Windows_95)/
    }, {
        s: 'Windows NT 4.0',
        r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
    }, {
        s: 'Windows CE',
        r: /Windows CE/
    }, {
        s: 'Windows 3.11',
        r: /Win16/
    }, {
        s: 'Android',
        r: /Android/
    }, {
        s: 'Open BSD',
        r: /OpenBSD/
    }, {
        s: 'Sun OS',
        r: /SunOS/
    }, {
        s: 'Linux',
        r: /(Linux|X11)/
    }, {
        s: 'iOS',
        r: /(iPhone|iPad|iPod)/
    }, {
        s: 'Mac OS X',
        r: /Mac OS X/
    }, {
        s: 'Mac OS',
        r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
    }, {
        s: 'QNX',
        r: /QNX/
    }, {
        s: 'UNIX',
        r: /UNIX/
    }, {
        s: 'BeOS',
        r: /BeOS/
    }, {
        s: 'OS/2',
        r: /OS\/2/
    }, {
        s: 'Search Bot',
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
    }];
    for (var id in clientStrings) {
        var cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
            os = cs.s;
            break;
        }
    }

    var osVersion = "unknown";

    if (/Windows/.test(os)) {
        if (/Windows (.*)/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)[1];
        }
        os = 'Windows';
    }

    switch (os) {
        case 'Mac OS X':
        // eslint-disable-next-line    
        if (/Mac OS X (10[\.\_\d]+)/.test(nAgt)) {
                // eslint-disable-next-line
                osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
            }
            break;
        case 'Android':
            // eslint-disable-next-line
            if (/Android ([\.\_\d]+)/.test(nAgt)) {
                // eslint-disable-next-line
                osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
            }
            break;
        case 'iOS':
            // eslint-disable-next-line
            if (/OS (\d+)_(\d+)_?(\d+)?/.test(nAgt)) {
                // eslint-disable-next-line
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
            }
            break;
        default:
            osVersion = osVersion || '';
    }

    if (browserInformationObject.BrowserName === 'Safari') {
        browserInformationObject.isSafari = true;
    }

    browserInformationObject.majorVersion = majorVersion;
    browserInformationObject.OsVer = os + " " + osVersion;
        
    if (browserInformationObject.isMobileDevice) {
        let pathname = window.location.pathname;
        if (pathname.charAt(pathname.length-1) === '/') {
            pathname = pathname.slice(1, pathname.length-1);
        } else
        {
            pathname = pathname.slice(1, pathname.length);
        }
        //console.error('window.location.hostname: ', window.location.hostname, 'pathname:', pathname, 'window.location.search', window.location.search);
        /*console.error('new URL: ', "https://" + window.location.hostname + "/indexMobile.html" + (
            window.location.search ? 
                window.location.search + "&show=" + encodeURIComponent(pathname) :
                '?show=' + encodeURIComponent(pathname)
            ));*/
        window.location.replace("https://" + window.location.hostname + "/indexMobile.html" + (
            window.location.search ? 
                window.location.search + "&show=" + encodeURIComponent(pathname) :
                '?show=' + encodeURIComponent(pathname)
            )
        );

        //window.location.replace("https://" + window.location.hostname + pathname + "/indexMobile.html" + window.location.search); 
        return null;
    }

    return browserInformationObject;
}